﻿.app-table {
	background-color: #ffffff;
	border-radius: 8px;
	width: 100%;

	thead {
		tr {
			th {
				padding: 12px 15px 12px 15px;
				font-weight: normal;
				color: #888;
				border-bottom: 1px solid #fafbfa;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 12px 15px 12px 15px;
			}

			&:nth-child(even) {
				td {
					background-color: #fafbfa;
				}
			}
		}
	}
}

body.dark-theme {
	.app-table {
		color: #fff;
		background-color: #2C3446;
	}

	.app-table > tbody > tr:nth-child(even) > td,
	.app-striped > tbody > tr:nth-child(even) > th {
		background-color: #2C3446;
	}


	.app-table > tbody > tr:nth-child(odd) > td,
	.app-striped > tbody > tr:nth-child(odd) > th {
		background-color: #323C50;
	}

	.app-table > thead > tr > th,
	.app-table > tbody > tr > th,
	.app-table > tfoot > tr > th,
	.app-table > thead > tr > td,
	.app-table > tbody > tr > td,
	.app-table > tfoot > tr > td {
		border: 0;
	}

	.table {
		color: #fff;
		background-color: #2C3446;
	}

	.table-striped > tbody > tr:nth-child(even) > td,
	.table-striped > tbody > tr:nth-child(even) > th {
		background-color: #2C3446; 
	}

	.table-striped > tbody > tr:nth-child(odd) > td,
	.table-striped > tbody > tr:nth-child(odd) > th {
		background-color: #323C50;
	}

	.table > thead > tr > th,
	.table > tbody > tr > th,
	.table > tfoot > tr > th,
	.table > thead > tr > td,
	.table > tbody > tr > td,
	.table > tfoot > tr > td {
		border: 0;
	}
}
