﻿@import '../../variables/_screen-sizes';

.landing-container {
	min-height: 652px;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 39px;
	display: grid;
	grid-gap: 25px;
	grid-template-columns: 1fr 1fr;

	&.one-col {
		grid-template-columns: 1fr
	}
}

.landing-col-left {
	width: auto;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
	image-rendering: auto;
}

.landing-col-right {
	width: auto;
	height: 100%;
	background-color: rgba(237, 229, 250, 0.5);
	padding-bottom: 39px;
}

.landing-col-heading {
	text-align: center;
	margin-top: 50px;
	font-family: "Volte-Semibold", "Volte", serif;
	color: #000000;
	font-size: 50px;
	letter-spacing: 0px;
	line-height: 1.5;
	font-weight: 600;
}

.landing-col-right-heading {
	margin-top: 50px;
	margin-left: 39px;
	font-family: "Volte-Semibold", "Volte", serif;
	color: #000000;
	font-size: 50px;
	letter-spacing: 0px;
	line-height: 1.5;
	font-weight: 600;
}

.landing-form {
	width: 66%;
	margin-left: auto;
	margin-right: auto;
}

.landing-login-button {
	margin-top: 39px;
	width: 100%;
	overflow: visible;
	background-color: #8300fd;
	border: 0px;
	border-radius: 4px;
	box-shadow: 0px 1px 4px 0px #443d69;
	font-family: "Volte-Medium", "Volte", serif;
	padding: 10px;
	color: #fff;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.3;
	font-weight: 500;
	text-align: center;
	text-decoration: none;

	&:hover,
	&:focus {
		color: #fff;
		text-decoration: none;
		filter: opacity(80%);
	}
}

.landing-col-right-text {
	font-family: "Volte", serif;
	color: #4f4f4f;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.3;
	//background-color: rgba(128, 128, 128, 0.33);
	a {
		color: #8300fd;
		text-decoration: none;

		&:hover,
		&:focus {
			color: #5C02B1;
		}
	}
}

.login-right-text {
	margin-left: 15%;
	margin-top: 39px;
	width: 209px;
	height: 108px;
}

@media (max-width: $phone-max) {
	.landing-container {
		display: block;
		width: 100%;
		min-height: auto;
		margin-bottom: 0px;
	}

	.landing-col-left {
		display: none;
	}

	.landing-col-right {
		padding-bottom: 39px;
	}

	.landing-col-right-heading {
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
		margin-left: 15px;
	}
}
