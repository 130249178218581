﻿.chosen-container {
	.chosen-choices {
		padding: 8px 6px;
		background-image: none;
		border: 1px solid #eee;
		border-radius: 4px;

		.search-field {
			input[type="text"].chosen-search-input {
				font-family: "Volte", serif;
				font-size: 13px;
			}
		}
	}
}

.dropdown-container {
	.input-field.input-validation-error {
		background-position-x: 88%;
	}
}

.input-dropdown {
	position: relative;

	* {
		user-select: none;
		cursor: default;
	}
}

.input-field.sort-dropdown {
	font-size: 13px;
	padding: 4px;
	display: inline-block;
	width: 175px;
	margin-bottom: 8px;
}

.dropdown-icon-down,
.dropdown-icon-up {
	position: absolute;
	width: 25px;
	height: 25px;
	right: 0;
	margin-top: 0px;
	margin-right: 12px;
	filter: opacity(80%);
}

.dropdown-icon-down {
	transform: rotate(180deg);
}

.dropdown-icon-up {
	transform: rotate(0deg);
}

.dropdown-options {
	width: 100%;
	font-family: "Volte", serif;
	overflow: visible;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eee;
	padding: 5px 0px 5px 0px;
	max-height: 250px;
	overflow-y: auto;
}

.dropdown-option-default,
.dropdown-option-display {
	padding: 10px 1px 10px 1px;
}

.dropdown-option-default {
	color: #888;
}

.dropdown-option {
	padding: 10px 10px 10px 10px;
	user-select: none;
	cursor: default;

	.fa {
		font-size: 18px;
		margin-right: 8px;
	}

	&:hover,
	&:focus {
		color: #fff;
		background-color: #8300fd;
		filter: opacity(80%);
	}
}

.dropdown-option-selected {
	color: #fff;
	background-color: #8300fd;
}

button.ui-multiselect,
.multiselect-dropdown.ui-multiselect {
	font-family: "Volte", serif;
	font-size: 16px;
	background: none !important;
	background-color: #fff !important;
	border: 0px !important;
	border-radius: 4px;
	font-weight: normal;
	padding: 12px 10px 12px 10px;

	&:focus {
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(255 255 255 / 23%) !important;
	}

	.ui-icon {
		background-image: none;
		background-position: unset;
		width: 16px;
		height: 16px;
		text-indent: 0px;

		&:after {
			display: block;
			font-size: 22px;
			content: "🢓";
			width: 16px;
			height: 16px;
		}
	}
}

.ui-multiselect-menu {
	font-size: 0.8em;
	top: 44px;

	ul, li {
		padding: 0px;
		margin: 0px;
		margin-left: -14px;
	}

	label {
		border: none;
		margin: 0px;
		font-weight: normal;
		font-size: 14px;
		display: grid;
		grid-template-columns: min-content 1fr;

		input[type="checkbox"] {
			margin-right: 5px;
			font-size: 14px;
			width: 0px;

			&:checked ~ span {
				background-color: #8300fd;
				color: #fff;

				&:before {
					content: "✓ ";
					color: #fff;
				}
			}
		}

		span {
			display: inline-block;
			padding: 15px;
			padding-left: 40px;
			width: 100%;
		}
	}

	label.ui-state-hover {
		border: none;
		border-radius: 0px;
		background: none;

		span {
			background-color: #999;
			color: #222;
		}
	}

	.ui-multiselect-filter-label {
		width: 100%;

		input[type="search"] {
			color: black;
			width: 75%;
		}
	}

	.ui-multiselect-close {
		display: none;
	}

	.ui-multiselect-checkboxes {
		&::-webkit-scrollbar {
			width: 10px;
			background-color: #433e66;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #8300fd;
		}
	}
}

.multiselect-dropdown,
.ui-multiselect-menu {
	&.ui-widget {
		font-family: "Volte", serif;
	}

	&.ui-widget-content {
		background: none;
		background-color: #fff;
		color: #222;
		border: 1px solid #aaa;
		border-radius: 0px;
	}
}

.ui-widget-header {
	background: none !important;
	border: none !important;
}

.ui-multiselect-filter-label {
	padding: 15px;

	input {
		margin-left: 15px !important;
		font-family: "Volte", serif;
		font-size: 16px !important;
		height: auto !important;
		background: none !important;
		background-color: #1F2739 !important;
		color: #ddd !important;
		border: 0px !important;
		border-radius: 4px;
		font-weight: normal;
		padding: 4px 10px !important;

		&:focus {
			box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(255 255 255 / 23%) !important;
		}
	}
}

.ui-multiselect-filter ~ ul.ui-helper-reset {
	li {
		padding-left: 32px;
	}
}

.expandable-panel-header {
	background-color: #e8dcfb;
	height: 66px;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: baseline;

	&:not(.fixed) {
		cursor: pointer;
	}

	.expandable-panel-header-text {
		display: inline-block;
		margin-left: 20px;
		font-weight: bold;
	}

	.expandable-panel-header-chevron {
		text-align: right;

		i {
			margin-right: 20px;
		}
	}
}

/* Make sure to also define a specific max-height pixel amount for any expandable-panels if animation is needed */
.expandable-panel {
	overflow: hidden;
	padding: 40px 0 40px 0;
	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: ease-out;

	.expandable-panel-body {
		display: grid;
		grid-template-columns: repeat(auto-fill, 200px);
		column-gap: 160px;
		row-gap: 20px;
		padding: 0 100px 0 100px;
	}

	&.closed {
		max-height: 0 !important;
		padding: 0;
	}

	h3 {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 2px;
		white-space: nowrap;
	}

	p {
		font-size: 14px;
		line-height: 20px;
		white-space: nowrap;
	}
}

body.dark-theme {
	button.ui-multiselect,
	.multiselect-dropdown.ui-multiselect,
	.chosen-container-multi .chosen-choices,
	.chosen-container .chosen-results,
	.dropdown-options {
		background-image: none;
		background-color: #1F2739;
		color: #ddd;

		.ui-icon {
			&:after {
				color: #ddd;
			}
		}
	}

	.chosen-container {
		.chosen-choices {
			border: 0px;

			li.search-field input[type="text"] {
				color: #ddd;
			}
		}
	}

	.multiselect-dropdown,
	.ui-multiselect-menu {
		&.ui-widget-content {
			background-color: #323C50;
		}
	}

	.ui-multiselect-menu {
		label.ui-state-hover {
			span {
				color: #ddd;
				background-color: #1F2739;
			}
		}
	}
}