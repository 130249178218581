﻿body.dark-theme {
	.panel {
		background: #1F2739;
		box-shadow: none !important;
		border-radius: 0 !important;

		.input-field,
		.form-control {
			color: #fff;
			background-color: #28324a;
			border: 0;
		}
	}

	.panel-body {
		color: #fff !important;
	}

	.panel-body a {
		color: #fff !important;
	}

	.expandable-panel-header {
		background-color: #4e586e !important;
	}

	.panel-default {
		border: 0 !important;
	}

	.panel-default > .panel-heading {
		background-color: #323C50;
		background-image: none;
		border: 0;
		color: #fff;
	}
}
