﻿licensekey-summary {
	&.deactivated {
		text-decoration: line-through;

		.licensekey-code-view,
		.licensekey-code-view > a {
			text-decoration: line-through;
		}

		.features > .feature {
			text-decoration: line-through;
		}
	}
}