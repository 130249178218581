﻿.input-wrapper .input-field.input-validation-error {
	background-image: url(/wwwroot/Content/Images/alert-triangle@4x.png) !important;
	background-repeat: no-repeat;
	background-size: 25px 25px;
	background-position: center right;
	background-position-x: 95%;
	border: 1px solid #eee;
	background-color: #C4A1E3 !important;
	color: #fff !important;
}

.input-wrapper .field-validation-error {
	font-family: "Volte-Light", "Volte", serif;
	color: #000000;
	font-size: 12px;
	letter-spacing: 0px;
	line-height: 1.3;
	text-align: right;
	display: block;
	margin-top: 2px;
}

.input-wrapper .field-validation-valid {
	display: none;
}

body.dark-theme {
	.input-wrapper .field-validation-error {
		color: #fff !important;
	}
}
