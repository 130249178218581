﻿.inside-app-heading-admin-container {
	grid-template-columns: 1fr;
}

.admin-dashboard {
	.admin-dashboard__charts-container {
		background-color: transparent;
		border-bottom: 5px solid #8117fb;
		padding: 39px;
		display: flex;
		flex-direction: row;

		.admin-dashboard__chart-container {
			width: 250px;
			max-width: 250px;
			background-color: transparent;
			padding: 15px;
			display: grid;
			grid-template-columns: 50px 150px;
			grid-column-gap: 25px;
			margin-right: 25px;

			.title {
				font-size: 16px;
				color: #222;
			}

			.stat {
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				height: 32px;
				position: absolute;
				top: 50px;
				text-align: center;
				width: 150px;
			}

			.substat {
				font-size: 14px;
			}

			.admin-dashboard__chart {
				width: 150px;
				height: 150px;
				position: relative;
			}
		}

		.admin-dashboard__chart-container:last-of-type {
			margin-right: 0px;
		}
	}

	.admin-dashboard__nav-container {
		margin-top: 39px;
		display: flex;
		flex-direction: row;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
		padding-left: 39px;
		padding-right: 5px;

		> .admin-dashboard__nav-section {
			margin: 0px 75px 75px 0px;
			width: 150px;

			.title {
				display: block;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
			}

			.bar {
				margin: 8px 0px 8px 0px;
				height: 8px;
				width: 100%;
			}

			.quick-stat {
				margin: 15px 0px 15px 0px;
				font-size: 15px;
				text-align: center;
			}

			.admin-dashboard__nav-links-wrapper {
				background-color: #555;
				min-height: 40vh;
			}

			.admin-dashboard__nav-links {
				background-color: transparent;
				width: 100%;
				padding-bottom: 1px;
			}

			a.admin-dashboard__nav-link {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				width: 100%;
				text-align: center;
				color: #fff;
				height: 60px;
				background-color: #777;
				font-size: 16px;
				border-radius: 8px;
				margin-bottom: 8px;
			}
		}

		> .admin-dashboard__nav-card:last-of-type {
			margin-right: 0px;
		}
	}
}

body.dark-theme {
	.admin-dashboard {
		.admin-dashboard__chart-container {
			background-color: #434343;

			.title {
				color: #fff;
			}
		}

		.admin-dashboard__nav-links {
			background-color: #201822;
		}
	}
}
