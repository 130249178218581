﻿.coaching {
	.coaching__log-meeting__wrapper {
		display: grid;
		grid-template-columns: 2fr 3fr;
		min-height: 500px;
		background-color: #e8dcfb;
		margin-left: -39px;
		width: calc(100% + 78px);
		position: relative;
		margin-bottom: 39px;

		.coaching_checklist {
			input[type="checkbox"] {
				display: none;

				&:checked ~ label {
					.img-circle {
						display: none;
					}

					.img-checked {
						display: block;
					}
				}

				&:disabled ~ label {
					cursor: default;
				}
			}

			.checklist_info_label {
				padding: 10px 5px 10px 5px;

				> .coaching-checklabel-container {
					&:hover {
						background-color: rgb(255, 255, 255, 0.1);
					}

					display: grid;
					align-items: center;
					grid-template-columns: minmax(300px, 1fr) 50px;
					padding: 5px 20px 5px 20px;
					margin: 10px;
					pointer-events: none;

					a, > label {
						pointer-events: auto;
					}

					> label {
						cursor: pointer;
						margin: 0px;

						.img-circle {
							display: block;
						}

						.img-checked {
							display: none;
						}
					}
				}
			}
		}

		.coaching__panel {
			&.disabled {
				input[type="submit"] {
					background-color: #4a4a4a;
				}

				input, select, textarea {
					filter: brightness(2) opacity(0.5);
					pointer-events: none;
				}
			}

			padding: 35px 75px 35px 75px;
			position: relative;
			z-index: 1;

			h2 {
				margin-top: 0px;
				font-size: 40px;
				font-weight: 600;
				font-family: Volte-Semibold, Volte, serif;
			}
		}
	}

	.coaching__log-meeting__wrapper::after {
		background-image: url('/wwwroot/Content/Images/coaching-plus.png');
		background-repeat: no-repeat;
		background-size: 150px;
		background-position: top left;
		content: "";
		top: 25px;
		left: 25px;
		position: absolute;
		width: 250px;
		height: 250px;
		pointer-events: none;
	}

	.change-group {
		display: grid;
		grid-template-columns: 40px 1fr;
		align-items: center;
		background-color: rgb(255, 255, 255, .05);
		padding: 10px;
		margin-bottom: 8px;
		border-radius: 10px;

		.coaching-changes-grid {
			display: none;
			grid-template-columns: 3fr 2fr 20px 2fr;
			grid-column-gap: 10px;
			grid-row-gap: 10px;
			grid-column-end: span 2;
			font-size: small;
			padding: 5px;

			> .item-desc {
				text-align: left;
				color: #a9a9a9;
				font-size: 1.1em;
			}

			> .item-old-value {
				font-style: italic;
				text-align: right;
				color: #c97e7e;
			}

			> .item-seperator {
				text-align: center;
			}

			> .item-new-value {
				font-weight: bold;
				text-align: left;
				color: #a0c7a0;
			}
		}
	}

	.collapse-toggle {
		cursor: pointer;

		&:hover {
			background-color: rgb(255, 255, 255, .25);
		}

		> i.fa {
			text-align: center;
		}

		&.shown > i.fa {
			transform: rotate(180deg);
		}
	}
}

body.dark-theme {
	.coaching {
		.coaching__log-meeting__wrapper {
			background-color: rgba(0, 0, 0, 0.1);
		}

		.coaching__log-meeting__wrapper::after {
			filter: invert(1);
		}
	}
}
