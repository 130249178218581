﻿#theme-ui-toggler {
	position: absolute;
	width: 150px;
	background-color: rgba(0, 0, 0, 0.9);
	border-radius: 8px;
	padding: 0px;
	z-index: 100001;

	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;

		li {
			padding: 0px;
			margin: 0px;

			button {
				width: 100%;
				background-color: transparent;
				border: none;
				box-shadow: none;
				text-align: left;
				padding: 8px 12px;
				color: #fff;

				&.selected {
					background-color: #8300fd;
				}
			}
		}
	}
}
