﻿.decision-matrix-occupation-detail {
	h1 {
		font-size: 1.4em;
		font-weight: bold;
	}

	h2 {
		font-size: 1.1em;
		font-weight: bold;
	}

	h3 {
		font-size: 1em;
		font-weight: bold;
	}

	.career-nav {
		cursor: pointer;
		padding-top: 10px;

		&.selected {
			font-weight: bold;
		}
	}

	.detail-panel {
		background-color: #fff;
	}

	.career-section {
		display: none;

		.split-panel {
			background-color: #fafafa;
		}

		.expansion-panel {
			background-color: #fbfbfb;

			.collapse {
				color: #777;
			}
		}

		.progress {
			border-radius: 0px;
			background-color: #9c62f8;
			background-image: none;
			text-align: right;
			font-size: 12px;
			color: #fff;
			margin-bottom: 0px;

			.progress-bar {
				background-color: rgba(255, 255, 255, 0.5);
				background-image: none;
			}
		}

		.progress-helper {
			color: #aaa;
			font-size: 11px;
			margin: 0px;
		}

		.show-less {
			display: none;
		}

		.show-more,
		.show-less {
			cursor: pointer;

			.fa {
				font-size: 12px;
			}
		}

		.more {
			display: none;
		}

		.help-block {
			font-size: 13px;
		}

		.analytic__number {
			font-size: 24px;
			font-weight: 300;
			color: #8300fd;
		}

		.analytic__description {
			color: #777;
		}

		.fa-fire {
			color: #8300fd;
		}

		#education-level {
			h4 {
				margin-top: 0px;
				margin-bottom: 0px;
				display: inline-block;
			}

			.progress {
				margin-bottom: 0px;
			}
		}

		#knowledge,
		#skills,
		#abilities,
		.interests,
		#work-activities,
		#work-context,
		#work-styles,
		#work-values {
			h2 {
				margin-bottom: 15px;
			}

			h3 {
				margin-top: 0px;
				margin-bottom: 0px;
			}

			.progress {
				margin-bottom: 0px;
			}
		}

		#core-tasks,
		#supplemental-tasks {
			.progress {
				margin-bottom: 0px;
			}
		}

		.interests {
			h4 {
				margin-top: 0px;
				margin-bottom: 0px;
			}
		}

		.job-listing__container,
		.edu-listing__container {
			h5 {
				font-size: 15px;
				font-weight: bold;
				color: #433e66;
			}
		}
	}
}

body.dark-theme {
	.decision-matrix-occupation-detail {
		.detail-panel {
			background-color: rgba(0, 0, 0, 0.2);
		}

		.career-section {
			.analytic__number {
				color: #b463ff;
			}

			.analytic__description {
				color: #b7acac;
			}

			.help-block {
				color: #aaa;
			}

			.split-panel {
				background-color: rgba(0, 0, 0, 0.3);
			}

			.expansion-panel {
				background-color: rgba(0, 0, 0, 0.3);
			}

			.job-listing__container,
			.edu-listing__container {
				h5 {
					color: #d3cdff;
				}
			}
		}
	}
}
