﻿body.dark-theme {
	.nav-tabs {
		border-bottom: 1px solid #202839 !important;
	}

	.nav-tabs > li > a {
		color: #202839 !important;
		background-color: #fff !important;
		text-decoration: none;
		border-radius: 0 !important;
	}

	.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
		color: #fff !important;
		background-color: #5b5362 !important;
		border: 1px solid #5b5362 !important;
		text-decoration: none;
	}

	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		color: #fff !important;
		border: 1px solid #202839 !important;
		background-color: #202839 !important;
		text-decoration: none;
	}
}
