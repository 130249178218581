﻿$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/_mixins';
@import '~@fortawesome/fontawesome-free/scss/_variables';
@import '~@fortawesome/fontawesome-free/scss/_icons';

@import '../../variables/_colors.scss';

navicons-base {
	display: flex; 
	align-self: center; 
}

navicons-start {
	@extend navicons-base;
	justify-self: flex-start; 
}

navicons-center {
	@extend navicons-base;
	justify-self: center; 
	font-size: 22px;

	navicon {
		margin: 2px;
	}
}

navicons-end {
	@extend navicons-base;
	justify-self: flex-end;
	font-size: 16px;
}

navlogo {
	display: flex;
	align-items: center;

	> logo {
		background-image: url('/wwwroot/Content/Images/marketing-logo.svg'); 
		background-size: contain;
		background-repeat: no-repeat;
		height: 25px; 
		width: 25px; 
		display: inline-block;
		margin-top: -2px;
		margin-left: 8px;
		margin-right: 8px;
	}

	> site {
		display: inline-block;
		margin-right: 4px;
		color: #aaa;
	}

	> text {
		display: inline-block;
		color: #fff;
		font-weight: bold;
	}
}

navtext {
	font-size: 14px;
	color: #aaa;
	padding-left: 16px;
	padding-right: 16px;

	a {
		font-size: 14px;
		color: #aaa;
	}
}

navicon {
	display: block;
	cursor: pointer;
	text-align: center;

	&:hover,
	&:focus {
		> icon {
			color: #fff;
			background-color: #393939;
			border-radius: 4px;
		}
	}

	&.selected {
		> icon {
			background-color: #393939;
			border-radius: 4px;
		}

		> underbar {
			display: block;
			border-bottom: 3px solid #8300fd;
			width: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	> icon {
		padding: 12px;
		color: #aaa;
		position: relative;

		&.profile {
			display: inline-block;
			line-height: 1;
			height: 48.750px;

			> img {
				vertical-align: unset;
			}
		}

		&.dashboard {
			@include fa-icon-solid($fa-var-signs-post);
		}

		&.assessment {
			@include fa-icon-solid($fa-var-shapes);
		}

		&.nextstep {
			@include fa-icon-solid($fa-var-cube);
		}

		&.courses {
			@include fa-icon-solid($fa-var-book-open-reader);
		}

		&.coaching {
			@include fa-icon-solid($fa-var-business-time);
		}

		&.takeaction {
			@include fa-icon-solid($fa-var-user-tie);
		}

		&.reports {
			@include fa-icon-solid($fa-var-pie-chart);
		}

		&.coachtools {
			@include fa-icon-solid($fa-var-handshake);
		}

		&.admintools {
			@include fa-icon-solid($fa-var-toolbox);
		}

		&.theme--auto {
			@include fa-icon-solid($fa-var-circle-half-stroke);
		}

		&.theme--light {
			@include fa-icon-solid($fa-var-sun);
		}

		&.theme--dark {
			@include fa-icon-solid($fa-var-moon);
		}

		&.notifications {
			@include fa-icon-solid($fa-var-bell);
		}

		&.support {
			@include fa-icon-solid($fa-var-question);
		}

		&.logout {
			@include fa-icon-solid($fa-var-right-to-bracket);
		}

		> badge {
			font-family: "Volte", serif;

			&.badge {
				font-size: 10px;
				background-color: #8300fd;
				color: #fff;
				padding: 3px 3px;
			}

			&.badge.badge--complete {
				background-color: rgba($success, 0.5);
				@include fa-icon-solid($fa-var-check-circle);
			}

			position: absolute;
			top: 0px;
			left: 24px;
		}

		> text {
			font-size: 9px;
			vertical-align: middle;
			text-transform: none;
			font-family: 'Volte-Light';
			font-weight: normal;
			white-space: nowrap;
		}
	}
}