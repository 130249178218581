﻿.nav-card-link {
	text-decoration: none;

	.nav-card {
		background-color: rgb(31, 39, 57, 0.1);
		color: #222;
		padding: 15px;
		min-height: 150px;
		height: 100%;

		.title {
			color: #222;
			font-weight: bold;
			font-size: 20px;
		}

		.description {
			color: #222;
			margin-top: 8px;
		}

		&--future-plans {
			background-color: #8300fd10;
			color: #eee;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;

		.nav-card {
			background-color: rgba(31, 39, 57, 0.4);

			&--future-plans {
				background-color: #8300fd40;
			}
		}
	}
}

.nav-card-section-title {
}

.nav-card-section {
	display: grid; 
	grid-template-columns: repeat(4, 1fr); 
	grid-auto-rows: 1fr; 
	grid-column-gap: 25px; 
	grid-row-gap: 25px;
	margin-bottom: 50px;
}

body.dark-theme {
	.nav-card-link {
		.nav-card {
			background-color: rgb(31, 39, 57);
			color: #fff;

			.title {
				color: #fff;
			}

			.description {
				color: #fff;
			}

			&--future-plans {
				background-color: #433e66;
				color: #eee;
			}
		}

		&:hover,
		&:focus {
			text-decoration: none;

			.nav-card {
				background-color: rgba(31, 39, 57, 0.6);

				&--future-plans {
					background-color: #433e6685;
				}
			}
		}
	}
}
