﻿.take-action-view {
	.take-action__next-step {
		width: 100%;
		margin-bottom: 15px;

		&:hover {
			input[type="radio"] + label {
				background-color: #8300fd;
				filter: opacity(0.8);
				color: #fff;
			}
		}

		input[type="radio"] {
			height: 0px;
			width: 0px;
			margin: 0px;
			position: absolute;
			top: 0px;
			left: 0px;
		}

		input[type="radio"] + label {
			background-color: #fff;
			cursor: pointer;
			padding: 15px;
			margin: 0px;
			width: 100%;
		}

		input[type="radio"]:checked + label {
			background-color: #8300fd;
			color: #fff;
		}
	}

	.take-action__next-steps__dialog {
		display: none;

		.speech-bubble {
			.bubble {
				position: relative;
				text-align: left;
				padding: 20px 30px;
				line-height: 1.3em;
				background-color: #fff;
				border: 1px solid transparent;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				border-radius: 8px;
			}

			.bubble-tail {
				width: 0;
				height: 0;
				position: relative;
				left: calc(100% - 60px);
				top: -5px;
				border-right: 30px solid white;
				border-bottom: 30px solid transparent;
				margin-bottom: 5px;
			}
		}
	}

	.take-action__next-steps__container {

		.take-action__next-steps__count__container {
			text-align: right;
			margin-bottom: 10px;
		}

		.take-action__next-steps__completed__container {
			display: none;
			margin-top: 25px;
			margin-bottom: 25px;
		}

		.take-action__next-steps {
			display: none;

			.take-action__next-steps__answer {
				width: 100%;
				margin-bottom: 25px;

				&:hover {
					input[type="checkbox"] + .take-action__next-steps__answer__label {
						background-color: #fafafa;
					}
				}

				input[type="checkbox"] {
					height: 0px;
					width: 0px;
					position: absolute;
					top: 0px;
					left: 0px;
				}

				input[type="checkbox"] + .take-action__next-steps__answer__label {
					background-color: #fff;
					padding: 15px;
					margin: 0px;
					width: 100%;
				}

				input[type="checkbox"]:checked + .take-action__next-steps__answer__label {
					.img-circle {
						display: none;
					}

					.img-checked {
						display: block;
					}
				}

				.take-action__next-steps__answer__label {
					cursor: pointer;
					display: grid;
					grid-template-columns: 5fr 35px 25px;
					grid-column-gap: 15px;

					label {
						cursor: pointer;
					}

					.img-circle {
						width: 35px;
						display: block;
					}

					.img-checked {
						width: 35px;
						display: none;
					}

					.img-chevron {
						transform: rotate(180deg);
						width: 25px;
					}
				}

				.take-action__next-steps__answer__details {
					display: none;
					padding: 25px;
					background-color: #e8dcfb;
					margin-left: 39px;
				}

				&.selected {
					.img-chevron {
						transform: rotate(0deg);
					}

					.take-action__next-steps__answer__details {
						display: block;
					}
				}
			}
		}
	}
}

.take-action__jobs {
	.decision-matrix .job .job__card {
		margin-bottom: 0px;
	}

	.decision-matrix .job .job__card .job__card__chevron {
		display: none;
	}

	.job__detail__container {
		max-width: 100%;
		background-color: #fff;
		padding: 25px;
	}
}