﻿$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/_mixins';
@import '~@fortawesome/fontawesome-free/scss/_variables';
@import '~@fortawesome/fontawesome-free/scss/_icons';
@import '../../variables/_colors';

$default-color: $purple;
$dark-color: #ddd;

date,
.input-label--date {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f073  ";
		padding-right: 4px;
	}

	> div:first-of-type {
		display: inline-block;
	}
}

address {
	margin-bottom: 0px;

	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f59f  ";
	}

	> div:first-of-type {
		display: inline-block;
	}
}

phone {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f095  ";
	}
}

username {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f2bb  ";
	}
}

email {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f0e0  ";
	}
}

education,
gradyear {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f19d  ";
	}
}

nationalorigin {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f57d  ";
	}
}

gender {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f228  ";
	}
}

person-name {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f183  ";
	}
}

yesno {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f772  ";
	}
}

status {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f011  ";
	}
}

unique {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f577  ";
	}
}

participant-id {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f2c1  ";
	}
}

notes {
	&::before {
		font: var(--fa-font-solid);
		color: $default-color;
		content: "\f328  ";
	}
}

body.dark-theme {
	date,
	.input-label--date {
		&::before {
			color: $dark-color;
		}
	}

	address {
		&::before {
			color: $dark-color;
		}
	}

	phone {
		&::before {
			color: $dark-color;
		}
	}

	username {
		&::before {
			color: $dark-color;
		}
	}

	email {
		&::before {
			color: $dark-color;
		}
	}

	education,
	gradyear {
		&::before {
			color: $dark-color;
		}
	}

	nationalorigin {
		&::before {
			color: $dark-color;
		}
	}

	gender {
		&::before {
			color: $dark-color;
		}
	}

	person-name {
		&::before {
			color: $dark-color;
		}
	}

	yesno {
		&::before {
			color: $dark-color;
		}
	}

	status {
		&::before {
			color: $dark-color;
		}
	}

	unique {
		&::before {
			color: $dark-color;
		}
	}

	participant-id {
		&::before {
			color: $dark-color;
		}
	}

	notes {
		&::before {
			color: $dark-color;
		}
	}
}
