﻿.alt-button,
.back-button,
.cancel-button,
.remove-button,
.save-button {
	font-family: "Volte-Medium", "Volte", serif;
	font-size: 14px;
	min-height: 36px;
	width: 144px;
	color: #ffffff;
	border: 0px;
	border-radius: 4px;
	-webkit-appearance: button;
	-moz-appearance: button;
	appearance: button;
	text-decoration: none;
	text-align: center;
	width: 100%;

	&:hover:enabled,
	&:focus:enabled {
		filter: opacity(80%);
		text-decoration: none;
	}

	&:disabled {
		background-color: grey;
	}
}

.save-button {
	background-color: #8300fd;
	box-shadow: 0px 1px 3px 0px #443d69;

	&:hover:enabled,
	&:focus:enabled {
		color: #ffffff;
	}

	.fa {
		margin-left: 8px;
	}
}

.alt-button,
.back-button {
	background-color: #fafafa;
	box-shadow: 0px 1px 3px 0px #afafaf;
	color: #666;

	&:hover:enabled,
	&:focus:enabled {
		color: #444;
	}

	.fa {
		margin-right: 8px;
	}
}

.cancel-button,
.remove-button {
	background-color: #ac71b5;
	box-shadow: 0px 1px 3px 0px #443d69;

	&:hover:enabled,
	&:focus:enabled {
		color: #fff;
	}

	.fa {
		margin-right: 8px;
	}
}

a.alt-button,
a.back-button,
a.cancel-button,
a.remove-button,
a.save-button {
	display: inline-block;
	font-size: 14px;
	padding: 8px 8px;
	min-height: 36px;
	margin: 0px;

	&:hover,
	&:focus {
		color: #ffffff;
		filter: opacity(80%);
		text-decoration: none;
	}
}

label.checkbox-button {
	font-size: 1em;
	font-weight: normal;
	cursor: pointer;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 35px auto;
	align-items: flex-start;
	user-select: none;

	img {
		height: 35px;
	}

	span {
		margin-top: 5px;
	}
}

.spin-hourglass {
	animation: SpinHourglassAnim 2s infinite ease-in-out;

	.hidden {
		display: none;
	}
}

@keyframes SpinHourglassAnim {
	0% {
		transform: rotate(0deg) scale(1);
	}

	50% {
		transform: rotate(180deg) scale(1.5);
	}

	100% {
		transform: rotate(180deg) scale(1);
	}
}

body.dark-theme {
	a.save-button {
		color: #fff !important;
	}

	.alt-button,
	a.alt-button {
		color: #777 !important;
	}
}