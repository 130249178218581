﻿.component--assessment {
	color: rgb(235, 121, 121);
}

.component--courses {
	color: rgb(250, 170, 250);
}

.component--coaching {
	color: rgb(153, 91, 186);
}

.component--license {
	color: rgb(138, 124, 242);
}
