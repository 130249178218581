﻿$dominant: #9568f2;
$accent: #8400ff;
$dark-dominant: #433e66;
$dark: #282828;
$dark-hover: darken($dark, 10%);
$alternate: #635e8a;

$hover: #9b62f8;
$selected: #8300fd;

$error: $selected;
$complete: #cfb1fd;
$complete-border: #9c62f8;

.assessment-questions-container {
	display: grid; 
	grid-template-columns: 1fr; 
	background-color: #e8dcfc; 
	padding: 27px;
}

.assessment-instructions {
	margin-bottom: 39px;
	max-width: 500px;
}

.question-container-context {

	&.multi {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 15px;
	}
}

.question-container-fader {
	display: none;
	height: 100%;
}

.question-container {
	background-color: #f9f9f9;
	line-height: 1.3;
	margin-bottom: 15px;
	color: #444;
	padding: 20px;
	border-radius: 8px;
	width: 100%;
	height: 100%;

	.prompt {
		text-align: center;
		padding-left: 40px;
		padding-right: 40px;
		font-weight: bold;
		margin-bottom: 25px;
		line-height: 1.35em;
		font-size: 18px;
	}

	.prompt-text {
	}

	.answer {
		input[type="checkbox"],
		input[type="radio"] {
			height: 0px;
			width: 0px;
		}
	}

	&.question-error {
		.question-error-container {
			grid-area: error;
			color: #666;
			font-size: 16px;
			font-weight: bold;
			display: block;
			margin-bottom: 15px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		.question-error-icon {
			color: red;
			font-size: 35px;
			display: inline;
			margin-right: 15px;
		}
	}

	&.question-complete {
		position: relative;
		background-color: $complete;
		border: 1px dotted $complete-border;

		.question-complete-icon {
			position: absolute;
			color: $selected;
			font-size: 35px;
			margin-top: -5px;

			.fa {
				display: block;
			}
		}

		.question-title {
			color: #bfbfbf;
		}

		.prompt {
			color: #fff;
		}

		.prompt-text {
			color: #fff;
		}
	}

	.question-title {
		color: #999;
		font-weight: 700;
		margin-bottom: 10px;
		padding-bottom: 0px;
		font-size: 24px;
	}

	.answer {
		label {
			display: block;
			border-radius: 8px;
			text-align: center;
			background-color: #ddd;
			padding: 8px;
			line-height: 24px;
			margin-bottom: 10px;

			&:hover {
				background-color: $hover;
				color: #fff;
				cursor: pointer;
			}
		}

		input {
			float: left;
			height: 24px;
			width: 20px;
			margin-left: 10px;
			margin-top: 7px;
		}

		input:checked + label {
			background-color: $selected;
			color: white;
			font-weight: bold;
		}

		input.incorrect + label {
			background-color: rgba(0, 0, 0, 0.2);
			color: white;
			cursor: not-allowed;
		}

		input.correct:checked + label {
			background-color: rgba(138,182,107,1);
			color: white;
		}
	}

	&.skinny {
		.answer {
			label {
				&:hover {
				}
			}

			input {
			}
		}
	}

	.no-select {
		padding: 6px 10px;
		padding-left: 35px;
		line-height: 24px;
		border-radius: 0px;
		margin: 0px;
		border-bottom: 1px solid #ececec;
		font-weight: 700;
	}

	.choices-left {
		float: left;
		margin-top: 25px;
	}

	.number {
		margin-bottom: 10px;
		padding-bottom: 5px;
		font-size: 24px;
	}

	&.horizontal {
		line-height: 1.3;
		margin-bottom: 15px;
		clear: both;
		.prompt {
			text-align: center;
		}

		.question-title {
			border-bottom: 0px;
			font-size: 24px;
			color: $dark;
		}

		.answer {
			label {
				font-size: 14px;
				display: block;
				line-height: 24px;
			}

			input {
				width: 1px;
				height: 1px;
				margin: 0px;
				position: absolute;
				top: 20px;
				left: 300px;
				z-index: -1;
				&.incorrect + label {
					background-color: rgba(0, 0, 0, 0.2);
					color: white;
					cursor: not-allowed;
				}

				&.correct:checked + label {
					background-color: rgba(138,182,107,1);
					color: white;
				}
			}
		}

		.choices-left {
			position: absolute;
			margin-top: 25px;
		}

		.number {
			margin-bottom: 10px;
			padding-bottom: 5px;
			font-size: 24px;
		}
	}

	&.horizontal-images {
		.prompt {
			&.gray {
			}
		}

		.choices {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 15px;
		}

		.answer {
			position: relative;
			display: inline-block;
			float: left;

			label {
				display: inline-block;
				float: left;
				margin: 0px;
				border-radius: 8px;
				border: 8px solid #ddd;

				&:hover {
					border: 8px solid $hover;
					background-color: #ddd;
				}

				img {
					pointer-events: none;
				}
			}

			input {
				position: absolute;
				bottom: 0px;
				left: 0px;
			}

			&.larger {
			}
		}

		.larger-choice {
			grid-area: larger-choice;
			margin-top: 15px;
			margin-left: calc(33% + 7.5px);
			margin-right: calc(33% + 7.5px);
		}

		.answerLabels {
			text-align: left;
			width: 100%;
			margin-bottom: -10px;
			color: #999;
		}

		&.question-complete {
			.prompt {
				img {
					background-color: white;
					border-radius: 8px;
				}
			}

			.question-complete-icon {
				bottom: 20px;
			}
		}

		.answer {
			input:checked + label {
				background-color: #f9f9f9;
				border: 8px solid $selected;
				border-radius: 8px;
			}

			input:checked + label > img {
				background-color: #f9f9f9;
				border-radius: 8px;
			}
		}
	}

	&.horizontal-image-select-list {
		.choices {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 15px;
		}

		.answer {
			display: block;

			label {
				border-color: rgba(0, 81, 115, 0.2);
				background-color: #ddd;
				border: 8px solid #ddd;

				&:hover {
					color: #999;
					border: 8px solid $hover;
				}
			}

			input {
				display: none;
			}

			img {
				pointer-events: none;
				height: 113px;
			}
		}

		.larger-choice {
			grid-area: larger-choice;
			margin-top: 15px;
			margin-left: calc(25% + 7.5px);
			margin-right: calc(25% + 7.5px);
		}

		.answerLabels {
			text-align: left;
			width: 100%;
			margin-bottom: -10px;
			color: #999;
		}

		.promptPhotoLabel {
			display: none;
		}

		.prompt {
			text-align: center;
			background-color: transparent;
		}

		&.question-complete {
			.prompt {
				img {
					background-color: white;
					border-radius: 8px;
				}
			}

			.answer {
				input:checked + label {
					background-color: #f9f9f9;
					border: 8px solid $selected;
					border-radius: 8px;
				}

				input:checked + label > img {
					background-color: #f9f9f9;
					border-radius: 8px;
				}

				.answerLabels {
					color: #999;
				}
			}
		}

		.field-validation-error {
			float: right;
			clear: both;
			margin-top: 5px;
		}

		img {
			height: 100px;
		}
	}

	&.horizontal-math-image {
		.prompt {
			display: inline-block;
			margin: 0px;
			border-color: rgba(3, 3, 3, 1);
			padding: 20px 5px 20px 5px;
			background-color: rgba(255, 255, 255, 1);
			border: 1px solid rgba(0, 0, 0, 0.18);
			margin-right: 20px;
			box-shadow: #ccc 0px 0px 3px;

			img {
				width: 200px;
			}
		}

		.answer {
			position: relative;
			display: inline-block;
			float: left;

			label {
				display: inline-block;
				float: left;
				margin: 0px;
				border-color: rgba(0, 81, 115, 0.2);
				border-radius: 0px;
				padding: 6px 20px 33px 20px;
				margin-top: 2px;
				margin-right: 2px;
				text-align: center;
				width: 120px;
			}

			&.larger {
			}

			input {
				position: absolute;
				bottom: 5px;
				left: 41px;
			}
		}

		.choices {
			display: inline-block;
			float: right;
			width: 50%;
		}

		.field-validation-error {
			float: right;
			clear: both;
			margin-top: 5px;
		}
	}

	&.name-comparison {
		&.question-complete {
			.prompt-right,
			.prompt-left {
				border-color: #f9f9f9;
			}
		}

		.prompt {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-gap: 0px;
			margin: 0 auto 25px auto;
			padding: 0;
			white-space: nowrap;
		}

		.prompt-right {
			text-align: left;
			padding: 10px 15px;
			border-left: 1px solid rgba(0,0,0,0.5);
			border-bottom: 1px solid rgba(0,0,0,0.5);
		}

		.prompt-left {
			float: right;
			text-align: right;
			padding: 10px 15px;
			border-bottom: 1px solid rgba(0,0,0,0.5);
		}

		.question-title {
			display: none;
		}

		.choices {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 15px;
		}

		.answer {
			label {
			}

			input {
			}
		}

		.number {
			margin-bottom: 10px;
			padding-bottom: 5px;
			font-size: 24px;
		}
	}

	&.personality-tiebreaker {
	}

	&.personality-tiebreaker-answer {
		.answer {
			width: 49%;
			display: inline-block;
		}
	}

	&.plan {
		display: block;

		.prompt {
			display: none;
		}

		.answer {
			label {
				text-align: left;
				background-color: transparent;

				&:hover {
					color: inherit;
					background-color: inherit;
					cursor: pointer;
				}
			}

			input {
				display: block;
				margin-right: 10px;

				&:hover {
					cursor: pointer;
				}
			}

			input:checked + label {
				background-color: inherit;
				color: inherit;
			}
		}
	}

	&.single-page {
		.prompt {
			text-align: center;

			img {
				width: auto;
			}
		}

		.choices {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 15px;
		}

		.larger-choice {
			grid-area: larger-choice;
			margin-top: 15px;
		}

		.answer {
			input {
			}

			label {
				text-align: center;
			}

			&.larger {
			}
		}

		&.question-complete {
			.prompt {
				img {
					background-color: white;
					border-radius: 8px;
				}
			}
		}
	}

	&.wip-card-container {
		display: grid;
		grid-template: "error" auto "prompt" auto "choices" 1fr / auto;
		grid-column-gap: 5px;
		grid-row-gap: 5px;
		align-items: start;

		&.question-complete {
			.wip-description {
				color: #444;
			}

			.question-complete-icon {
				top: 10px;
				left: 10px;
			}
		}

		.wip-card-prompt {
			.wip-radio-answer-label {
				background-color: #ddd;
				border: 1px solid #cfb1fd;
				border-radius: 50%;
				height: 35px;
				width: 35px;
				text-align: center;
				padding-top: 7px;
				cursor: pointer;
			}

			.wip-radio-answer {
				// Have to do this because display: none doesn't work with jQuery validation:
				height: 0px;
				width: 0px;
			}

			.wip-radio-answer:hover + .wip-radio-answer-label {
				background-color: $hover;
				color: #fff;
			}

			.wip-radio-answer:checked + .wip-radio-answer-label {
				border: 1px solid $selected;
				background-color: $selected;
				color: #fff;
			}
		}

		.wip-card-detail {
			padding: 0px;
			height: 100%;

			.prompt {
				font-weight: normal;
				text-align: left;
				font-size: 16px;
				padding: 0px;
				height: 100%;
			}

			.prompt-text {
				height: 100%;

				.wip-container {
					display: grid;
					grid-template: "title title" min-content "description list" auto / auto auto;
					height: 100%;

					.wip-title {
						grid-area: title;
						text-align: center;
						padding-left: 40px;
						padding-right: 40px;
						font-weight: bold;
						margin-top: 15px;
						margin-bottom: 25px;
						line-height: 1.35em;
						font-size: 18px;
					}

					.wip-description {
						grid-area: description;
						background-color: $dominant;
						color: #fff;
						padding: 15px;
						height: 100%;
					}

					.wip-list {
						grid-area: list;
						background-color: #fafafa;
						color: #444;
						padding: 15px;

						ul {
							padding: 0px;

							li {
								margin-bottom: 15px;
								list-style: none;
								background-image: url('/wwwroot/Content/Images/circle-check-icon.png');
								background-repeat: no-repeat;
								background-position: left top;
								background-size: 35px;
								padding-left: 45px;
								padding-top: 5px;
								min-height: 35px;
							}
						}
					}
				}
			}
		}
	}
}

body.dark-theme {
	.assessment-questions-container {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.question-container {
		background-color: rgba(0, 0, 0, 0.1);
		border: 1px solid #555;
		color: #ddd;

		.answer {
			label {
				background-color: rgba(0, 0, 0, 0.2);
				border: 1px solid #444;

				&:hover {
					background-color: $hover;
					color: #fff;
				}
			}

			input:checked + label {
				background-color: $selected;
				color: white;
			}

			input.incorrect + label {
				background-color: rgba(0, 0, 0, 0.2);
				color: white;
			}

			input.correct:checked + label {
				background-color: rgba(138,182,107,1);
				color: white;
			}
		}

		&.question-error {
			.question-error-container {
				color: #fff;
			}

			.question-error-icon {
				color: red;
			}
		}

		&.question-complete {
			background-color: darken($complete, 80%);
			border: 1px dotted darken($complete-border, 80%);

			.question-complete-icon {
				color: $selected;
			}

			.question-title {
				color: #bfbfbf;
			}

			.prompt {
				color: #fff;
			}

			.prompt-text {
				color: #fff;
			}
		}

		&.horizontal-image-select-list {
			.answer {
				label {
					background-color: #fff;
					border: 8px solid #fff;

					&:hover {
						color: #999;
						border: 8px solid $hover;
					}
				}
			}

			img {
				background-color: rgba(255, 255, 255, 0.9);
				border-radius: 8px;
			}
		}

		&.single-page {
			.prompt {
				img {
					background-color: #fff;
					border-radius: 8px;
				}
			}

			&.question-complete {
				.prompt {
					img {
						background-color: white;
						border-radius: 8px;
					}
				}
			}
		}

		&.name-comparison {
			&.question-complete {
				.prompt-right,
				.prompt-left {
					border-color: #f9f9f9;
				}
			}

			.prompt-right {
				border-left: 1px solid rgba(255,255,255,0.5);
				border-bottom: 1px solid rgba(255,255,255,0.5);
			}

			.prompt-left {
				border-bottom: 1px solid rgba(255,255,255,0.5);
			}
		}

		&.horizontal-images {
			.answer {
				label {
					background-color: #fff;
					border: 8px solid #fff;

					&:hover {
						border: 8px solid $hover;
						background-color: #fff;
					}

					img {
						background-color: #fff;
						border-radius: 8px;
					}
				}
			}

			&.question-complete {
				.prompt {
					img {
						background-color: white;
					}
				}
			}

			.answer {
				input:checked + label {
					background-color: #f9f9f9;
					border: 8px solid $selected;
				}

				input:checked + label > img {
					background-color: #f9f9f9;
				}
			}
		}

		&.wip-card-container {
			&.question-complete {
				.wip-description {
					color: #444;
				}

				.question-complete-icon {
				}
			}

			.wip-card-prompt {
				.wip-radio-answer-label {
					background-color: rgba(0, 0, 0, 0.1);
					border: 1px solid #cfb1fd;
				}

				.wip-radio-answer {
				}

				.wip-radio-answer:hover + .wip-radio-answer-label {
					background-color: $hover;
					color: #fff;
				}

				.wip-radio-answer:checked + .wip-radio-answer-label {
					border: 1px solid $selected;
					background-color: $selected;
					color: #fff;
				}
			}

			.wip-card-detail {
				.prompt-text {
					.wip-container {
						.wip-description {
							background-color: $dominant;
							color: #fff;
						}

						.wip-list {
							background-color: rgba(0, 0, 0, 0.1);
							color: #444;

							ul {
								li {
								}
							}
						}
					}
				}
			}
		}
	}
}
