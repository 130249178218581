﻿$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/_mixins';
@import '~@fortawesome/fontawesome-free/scss/_variables';

@import '../../variables/colors';

licensekey-dates {
	margin-bottom: 4px;

	date-time {
		display: block;
	}

	&.deactivated {
		text-decoration: line-through;

		info {
			background-color: rgba($danger, 0.1);
			text-decoration: line-through;

			start, end, date-time, date, time {
				text-decoration: line-through;
			}
		}
	}

	&.expired {
		info {
			background-color: rgba($danger, 0.1);
		}
	}

	&.expiring {
		info {
			background-color: rgba($warning, 0.1);
		}
	}

	info {
		display: grid;
		grid-template-columns: repeat(2, auto);
		column-gap: 8px;
		font-size: 13px;
		background-color: rgba(255, 255, 255, 0.1);
		border: 0px;

		start {
			justify-self: flex-start;
		}

		end {
			justify-self: flex-end;
			text-align: right;
		}

		start, end {
			display: inline-block;
			margin: 4px 8px;

			date-time > date {
				display: block;
			}

			date-time > time {
			}

			* {
				font-size: 12px;
			}
		}

		start time:before, end time:before {
		}

		start time:before {
			content: "Starts  ";
		}

		end time:before {
			content: "Expires  ";
		}
	}

	message {
		display: block;
		font-size: 13px;
		font-weight: bold;
		text-align: center;
	}
}

body.dark-theme {
	licensekey-dates {
		info {
			background-color: rgba(0, 0, 0, 0.1);

			start, end {
				color: #fff;

				date-time > date {
					color: #fff;
				}

				date-time > time {
					color: #aaa;
				}
			}

			start time:before, end time:before {
				color: #aaa;
			}
		}

		message {
			color: #fff;
		}
	}
}