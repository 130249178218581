﻿.toggle-checkbox {
	input[type=checkbox] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 50px;
		height: 25px;
		background: #bbb;
		display: block;
		border-radius: 25px;
		position: relative;
	}

	label:after {
		content: '';
		position: absolute;
		top: 5px;
		left: 5px;
		width: 15px;
		height: 15px;
		background: #433e66;
		border-radius: 15px;
		transition: 0.3s;
	}

	input:checked + label {
		background: #8300fd;
	}

	input:checked + label:after {
		left: calc(100% - 5px);
		transform: translateX(-100%);
	}

	label:active:after {
		width: 30px;
	}
}

body.dark-theme {
	.toggle-checkbox {
		label {
			background: #333;
		}

		input:checked + label {
			background: #8300fd;
		}
	}
}
