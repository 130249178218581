﻿$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/_mixins';
@import '~@fortawesome/fontawesome-free/scss/_variables';

.licensekey-icon {
	@include fa-icon-solid($fa-var-share-nodes);
	color: #9c62f8;

	&.licensekey-icon--syncing {
		@include fa-icon-solid($fa-var-cloud-arrow-up);
		color: #9c62f8;
	}

	&.licensekey-icon--primary {
		@include fa-icon-solid($fa-var-square-share-nodes);
		color: #d3c13f;
	}

	&.licensekey-icon--primary-syncing {
		@include fa-icon-solid($fa-var-cloud-arrow-up);
		color: #d3c13f;
	}

	&.licensekey-icon--unusable {
		@include fa-icon-solid($fa-var-skull-crossbones);
		color: #c5407a;
	}

	&.licensekey-icon--deactivated {
		@include fa-icon-solid($fa-var-skull);
		color: #c5407a;
	}
}
