﻿.sectional-progress-bar-container {
	transform: translate(0, -50%);
	height: 35px;
	background-color: rgba(255, 255, 255, 0.8);

	.sectional-progress-bar {
		background-color: #5c408f;
		background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		width: 0;
		height: 100%;
		transition-property: width;
		transition-timing-function: linear;
	}

	span {
		position: absolute;
		top: 50%;
		right: 1.5%;
		transform: translate(0, -50%);
		color: #fff;
		display: inline-block;
		background-color: #8300fd;
		border-radius: 4px;
		padding: 2px 6px;
		font-size: 14px;
		font-family: "Volte-Medium", serif;
	}
}

.up-next {
	.activity-progress-bar.activity-progress-bar--unlocked {
		background-image: url(/wwwroot/Content/Images/Full/favorite-icon.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position-y: -8px;
		background-position-x: -8px;
		padding: 50px;
		border: 0px;
		border-radius: 0px;
		background-color: transparent;

		.bar {
			border: 1px solid rgba(255, 255, 255, 0.2);

			&._Reports {
				background-color: #d3ad5e;
			}

			&._TakeAction {
				background-color: #bc80f3;
			}
		}

		.completed {
			height: 48px;
		}
	}
}

.activity-progress-bar {
	a {
		color: #222;
	}

	&.activity-progress-bar--unlocked {
		border: 3px solid #8300fd;
		border-radius: 4px;
		background-color: #8300fd;
		padding: 1px;

		.etc {
			color: #fff;
		}
	}

	&.activity-progress-bar--locked {
		opacity: 0.5;
	}

	&.activity-progress-bar--completed {
		opacity: 1.0;
	}

	&.activity-progress-bar--unlocked .bar,
	.activity-progress-bar-container .completed {
		&._InterestProfiler {
			background-color: #656083;
		}

		&._PersonalityTemperament {
			background-color: #9bbdda;
		}

		&._ArithmeticReasoning {
			background-color: #e59de9;
		}

		&._Vocabulary {
			background-color: #95beb7;
		}

		&._3DSpace {
			background-color: #637585;
		}

		&._Computation {
			background-color: #823968;
		}

		&._NameComparison {
			background-color: #42587e;
		}

		&._ObjectMatching {
			background-color: #64417d;
		}

		&._DiagnosticReasoning {
			background-color: #4b7d74;
		}

		&._AnalyticReasoning {
			background-color: #e59de9;
		}

		&._WorkImportanceSortingResult {
			background-color: #95beb7;
		}

		&._AssessmentResults {
			background-color: #9bbdda;
		}

		&._CareerMatches {
			background-color: #4b7d74;
		}

		&._NextSteps {
			background-color: #42587e;
		}
	}

	.activity-progress-bar-container {
		.fa {
		}

		.activity-number {
			display: inline-block;
			position: absolute;
			top: 1px;
			left: 0;
			margin-top: 12px;
			margin-left: 12px;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			font-size: 16px;
			text-align: center;
			background-color: rgba(255, 255, 255, 0.2);
			color: #fff;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.title {
			position: absolute;
			top: 15px;
			left: 42px;
			font-size: 14px;
			color: #fff;
		}

		.bar {
			display: block;
			position: relative;
			top: 0;
			left: 0;
			height: 50px;
			background-color: rgba(0, 0, 0, 0.2);
		}

		.completed {
			display: block;
			position: relative;
			top: 0;
			left: 0;
			height: 50px;
			background-color: rgba(0, 0, 0, 0.2);
			// width is set via code in attribute as a percent of completion
		}
	}

	.etc {
		color: #aaa;
		font-size: 14px;
		text-align: right;
	}
}

body.dark-theme {
	.activity-progress-bar {
		.etc {
			color: #ddd;
		}
	}
}
