﻿.user-href,
.action-icon--admin ~ a {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.user-href img {
	margin-top: 1px;
	margin-bottom: 1px;
}