﻿.input-wrapper {
	margin-top: 25px;
}

.input-label {
	min-height: 18px;
	font-family: "Volte", serif;
	color: #000000;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 1.3;
	text-align: left;
	display: block;
}

.input-field {
	width: 100%;
	font-family: "Volte", serif;
	overflow: visible;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eee;
	padding: 12px 10px 12px 10px;
}

.input-field-disabled {
	width: 300px;
	padding: 12px 10px 12px 10px;
	overflow: visible;
	background-color: #dfd1eb;
	border-radius: 4px;
	border: 1px solid #eee;
}

.input-field-placeholder {
	width: 162px;
	height: 27px;
	font-family: "Volte", serif;
	color: #999999;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.5;
	text-align: left;
}

.input-field-helper {
	font-family: "Volte-Light", "Volte", serif;
	color: #000000;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 1.3;
	text-align: right;
	margin-top: 4px;
	text-decoration: none;

	a {
		display: block;
		color: #8300fd;
		font-size: inherit;

		&:hover,
		&:focus {
			color: #5C02B1;
		}
	}
}

.input-real-input {
	font-family: "Volte", serif;
	color: #232323;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.5;
	text-align: left;
}

.option-active,
.input-wrapper select.input-field option:checked {
	overflow: visible;
	background-color: #8300fd;
	color: #fff;
	padding: 10px;
}

.option-item,
.input-wrapper select.input-field option {
	font-family: "Volte", serif;
	color: #222;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.5;
	text-align: left;
	padding: 10px;
}

ul.chosen-choices {
	li, input {
		color: #222 !important;
	}
}

.inside-app-frame {
	.input-wrapper select.input-field option {
		font-size: 16px;
	}
}

body.dark-theme {
	.input-field {
		color: #ddd;
		background-color: #1F2739;
		border: 0;

		option {
			background-color: #1F2739; // Overrides the validation error color for individual rows
		}
	}

	.input-field:focus,
	.input-field:focus-visible {
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.23) !important;
	}

	.option-active,
	.input-wrapper select.input-field option:checked {
		background-color: #8300fd;
		color: #fff;
	}

	.option-item,
	.input-wrapper select.input-field option {
		color: #999;
	}

	ul.chosen-choices {
		li, input {
			color: #ddd !important;
		}

		> li.search-choice {
			background-image: linear-gradient(#8300fd, #50009b);
			border-color: black;
			box-shadow: 0 0 2px #00000080 inset;
			color: #ddd !important;
		}
	}
}
