﻿.alert {
	margin: 0px auto 25px auto;
	padding: 15px 25px 15px 95px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	background-color: #9b62f8;
	color: #fff;
	font-family: "Volte", serif;
	display: block;
	width: max-content;
	min-width: 350px !important;
	border: 1px solid #9b62f8;
	border-radius: 4px;
}

.alert-danger {
	background-image: url(/wwwroot/Content/Images/notifications-failure.png),
		linear-gradient(to right, #9b62f8, #9b62f8);
	background-repeat: no-repeat, no-repeat;
	background-position: left, center;
	background-size: 82px, auto;
	background-position-x: 10px;
}

.alert-warning {
	background-image: url(/wwwroot/Content/Images/notifications-warning.png), 
		linear-gradient(to right, #9b62f8, #9b62f8);
	background-repeat: no-repeat, no-repeat;
	background-position: left, center;
	background-size: 82px, auto;
	background-position-x: 10px;
}

.alert-info {
	background-image: url(/wwwroot/Content/Images/notifications-notice.png),
		linear-gradient(to right, #9b62f8, #9b62f8);
	background-repeat: no-repeat, no-repeat;
	background-position: left, center;
	background-size: 82px, auto;
	background-position-x: 10px;
}

.alert-success {
	background-image: url(/wwwroot/Content/Images/notifications-success.png),
		linear-gradient(to right, #9b62f8, #9b62f8);
	background-repeat: no-repeat, no-repeat;
	background-position: left, center;
	background-size: 82px, auto;
	background-position-x: 10px;
}
