﻿@import '../../variables/_screen-sizes';

.profile-edit {
	.input-wrapper-collapse {
		margin-top: 0px;
	}

	.input-wrapper-collapse-lg {
		margin-top: 0px;
	}

	.main-container {
		max-width: 1500px;

		.tab-panel {
			.tab-content {
				padding: 0px 25px 0px 25px;
				padding-bottom: 60px;

				.content-group {
					padding-bottom: 40px;

					h4 {
						font-weight: bold;
					}
				}

				label {
					font-weight: normal;
				}

				.label-descriptor {
					display: block;
					font-weight: lighter;
					color: #000000;
				}

				.container {
					max-width: 100%;
				}
			}
		}

		.tab-panel.side-bar {
			height: 100%;
			background-color: #ffffff;

			.tab-link {
				display: block;
				padding: 25px 20px;
				align-content: center;
				color: #444444;
				text-decoration: none;

				&.active {
					background-color: #a33fff;
					color: #ffffff;
				}

				&:hover {
					background-color: #8300fd;
					color: #fff;
					cursor: pointer;
				}
			}
		}
	}
}

body {
	p {
		color: #777;
	}

	&.dark-theme {
		p {
			color: #ccc;
		}

		.profile-edit {
			.main-container {
				.tab-panel {
					.tab-content {
						.label-descriptor {
							color: #737373;
						}
					}
				}

				.tab-panel.side-bar {
					background-color: #1F2739;

					.tab-link {
						color: #ffffff;
					}
				}
			}
		}
	}
}

@media (max-width: 992px) {
	.profile-edit {
		.input-wrapper-collapse-lg {
			margin-top: 25px;
		}
	}
}

@media (max-width: $tablet-max) {
	.profile-edit {
	}
}

@media (max-width: $phone-max) {
	.profile-edit {
	}
}
