ul.baraja-container {
	/*width: 200px;*/
	/*height: 310px;*/
	margin: 100px 10px 0px 130px;
	position: relative;
	padding: 0;
	list-style-type: none;
}

ul.baraja-container li {
	/*width: 100%;
	height: 100%;*/
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	background: #fff;
	pointer-events: auto;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}

.no-js ul.baraja-container {
	width: auto;
	height: auto;
	text-align: center;
}

.no-js ul.baraja-container li {
	position: relative;
	display: inline-block;
	/*width: 200px;
	height: 310px;*/
	margin: 10px;
}