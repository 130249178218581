﻿.datepicker {
	background: #d2bef3;
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
	color: #fff;
}

.datepicker--day-name {
	color: #9C62F8;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
	color: #f9f3f3;
}

.datepicker--cell.-current- {
	color: #9a15ff;
}

.datepicker--nav-title i {
	font-style: normal;
	color: #ffffff;
	margin-left: 5px;
}

.datepicker--nav-action path {
	fill: none;
	stroke: #ffffff;
	stroke-width: 2px;
}

.datepickers-container {
	z-index: 1000000;
}
