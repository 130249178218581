﻿@import '../../variables/_colors.scss';

.dashboard-home-index,
.dashboard-assessment-index,
.dashboard-coaching-index,
.dashboard-courses-index,
.dashboard-careers-index {
	h2 {
		font-size: 20px;
		font-weight: 400;
		margin-top: 45px;
	}

	h3 {
		font-size: 18px;
		margin-top: 35px;
	}

	div > h2:first-of-type {
		margin-top: 0px;
	}

	div > h3:first-of-type {
		margin-top: 15px;
	}

	ul {
		padding-left: 0px;
	}

	ul > li {
		list-style: none;
	}

	ul > li > a,
	ul > li > a:hover {
		font-size: 16px;
		text-decoration: none;
	}

	ul > li > a:hover,
	ul > li > a:hover .fa,
	ul > li > a:hover .fa-solid {
		color: #9c62f8;
	}

	.account-icon,
	.link--accounts .fa-solid,
	.link--next-step .fa-solid,
	.link--assessment .fa-solid,
	.link--take-action .fa-solid,
	.search__results--accounts .fa-solid {
		color: #eb7979;
	}

	.link--license-keys .fa-solid,
	.link--courses .fa-solid {
		color: #faaafa;
	}

	.link--coaches .fa-solid,
	.link--coaching .fa-solid,
	.link--coach-tools .fa-solid {
		color: #995bba;
	}

	.link--users .fa-solid,
	.link--reports .fa-solid,
	.search__results--users .fa-solid {
		color: #8a7cf2;
	}

	.link--application-features .fa-solid,
	.link--application-notifications .fa-solid,
	.link--types .fa-solid,
	.link--imports .fa-solid {
		color: #aaa;
	}

	.search__input-wrapper {
		display: grid;
		grid-template-columns: 50px auto;

		.search__input-icon {
			padding: 12px 10px;
			border: 1px solid #eee;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			text-align: center;
			background-color: #fff;
			border-right: 0px;

			i.fa-solid {
				color: #222;
			}
		}

		.input-field {
			border-left: 0px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;

			&:focus,
			&:focus-visible {
				outline: none;
				box-shadow: none !important;
			}
		}

		&.focused {
			border: 1px solid #fff;
			border-radius: 4px;
		}
	}

	.search__results-container {
		display: none;
		background-color: #fff;
		width: 100%;
		min-height: 100px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		.search__results {
			display: flex;
			flex-direction: row;
			padding: 16px;

			> div {
				margin-right: 150px;
			}

			> div:last-of-type {
				margin-right: 0px;
			}

			h4 {
				margin-top: 0px;
			}

			.search__results--users {
				img {
					width: 25px;
					margin-top: -5px;
					margin-bottom: 5px;
					margin-right: 5px;
				}
			}
		}
	}

	.dashboard__charts-loader {
	}

	.dashboard__user-not-ready,
	.dashboard__offline {
		background-color: #2C3446;
		padding: 15px;
		font-size: 14px;
		color: #999;
	}

	.dashboard__last-loaded {
		font-size: 14px;
		color: #999;
		text-align: right;
	}

	.dashboard__charts-filters {
		background-color: #fff;
		padding: 15px;
		margin: 0px;

		.account-search-control {
			padding: 0px;
		}

		.filters-grid {
			margin-bottom: 16px;
			width: 100%;

			> .input-wrapper {
				margin-right: 0px;
			}

			select {
				padding: 8px 6px;
				font-size: 14px;
				margin-bottom: 4px;

				option {
					font-size: 14px;
				}
			}

			.ui-multiselect {
				padding: 8px 6px;
				font-size: 14px;
				margin-bottom: 4px;
			}

			.year-filters-grid {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 16px;
				grid-row-gap: 4px;
				margin-bottom: 8px;
			}

			.attribute-filters-grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 16px;
				grid-row-gap: 4px;
			}
		}

		.input-label {
			font-weight: normal;
			color: #aaa;
		}
	}

	.dashboard__charts-container {
		background-color: #fff;
		padding: 15px;

		.title {
			font-size: 16px;
			margin-top: 0px;
		}

		h3 {
			margin-top: 0px;
		}

		h4 {
			font-size: 14px;
		}

		.dashboard__chart-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 25px;
			margin-top: 8px;
			margin-bottom: 16px;

			.main-title {
				font-size: 16px;
			}

			.title {
				font-size: 14px;
			}

			.substat__chart,
			.substat__lineChart {
				font-size: 14px;
			}

			.dashboard__chart {
				width: 100%;
				margin-top: 16px;
				padding-top: 8px;
			}

			.dashboard__lineChart {
				margin-top: 16px;
				padding-top: 8px;
			}

			.day-count-select {
				padding: 8px 6px;
				font-size: 14px;
				margin-bottom: 4px;

				option {
					font-size: 14px;
				}
			}
		}
	}

	.inbox-table {
		tbody {
			tr {
				td {
					font-size: 14px;
				}

				&.read {
					color: #aaa;
				}

				&.unread {
					font-weight: bold;
				}

				&.read:hover td,
				&.unread:hover td {
					background-color: #1f2739;
					cursor: pointer;
				}
			}
		}
	}

	.account-search-control {
		padding: 15px;
	}

	.auto-grid {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: flex-start;

		> .input-wrapper {
			flex-basis: content;
			margin-right: 16px;
		}

		.ui-multiselect {
			padding: 8px 6px;
			font-size: 14px;
			margin-bottom: 4px;
		}

		.input-label {
			font-weight: normal;
			color: #aaa;
		}
	}
}

.dashboard-home-index {
	.dashboard__charts-container {
		background-color: #fff;

		.dashboard__chart-container {
			.dashboard__chart__link--assessment,
			.dashboard__chart__link--coaching,
			.dashboard__chart__link--courses {
				text-decoration: none;

				&:hover {
					.dashboard__chart,
					.dashboard__lineChart {
						background-color: rgba(0, 0, 0, 0.1);
					}
				}
			}
		}
	}

	.dashboard__career-charts-container {
		background-color: #fff;
		padding: 15px;
		display: none;
		font-size: 14px;

		> .col-md-12 {
			padding: 0px;
		}

		.dashboard__last-loaded {
			align-self: flex-start;
			align-items: center;
			color: #777;
			text-align: left;
			margin-bottom: 16px;
		}

		.take-action-path-title {
		}

		.dashboard__take-action-path-container {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: 1fr;
			column-gap: 8px;
			row-gap: 8px;
			text-align: center;
			justify-content: center;
			margin-top: 8px;
			background-color: rgba(240, 240, 240, 0.5);

			.no-match {
				color: #888;
				font-style: italic;
			}

			a {
				text-decoration: none;

				.dashboard__take-action-path-container-column {
					height: 100%;
					padding: 8px 16px;

					.dashboard__chart--take-action-path {
						width: 100%;
						padding-top: 8px;
					}

					.take-action-path-title {
						margin-top: 8px;
						font-size: 13px;
					}
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.1);

					> * {
						background-color: rgba(0, 0, 0, 0.1);
					}
				}
			}
		}

		.dashboard__top-match-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 15px;

			.match-header {
				margin-top: 25px;
			}
		}

		.dashboard__match-container {
			background-color: rgba(240, 240, 240, 0.5);
			margin-top: 8px;

			.dashboard__match-container-row {
				display: grid;
				grid-template-columns: 3fr 1fr 1fr;
				column-gap: 15px;
				row-gap: 8px;
				padding: 8px 16px 16px 16px;
			}

			.no-match {
				color: #888;
				font-style: italic;
			}

			.match-header {
				font-size: 12px;
				text-align: right;
				margin-top: 0px;
				color: #a79fae;
			}

			.match-stat {
				text-align: right;
			}

			a {
				text-decoration: none;

				.match-title {
					font-size: 14px;
					word-break: break-word;
				}

				.match-percent {
					font-size: 14px;
				}

				.match-number {
					font-size: 13px;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.1);

					> * {
						background-color: rgba(0, 0, 0, 0.1);
					}
				}
			}

			.match-title {
				font-size: 14px;
			}

			.match-percent {
				font-size: 14px;
				opacity: 0.8;
			}

			.match-number {
				font-size: 13px;
			}
		}

		.dashboard__top-favorite-career-matches-container {
			display: grid;
			grid-template-columns: repeat(3, 33%);
			column-gap: 8px;
			margin-right: 8px;

			.favorite-header {
				margin-top: 25px;
			}

			.dashboard__top-favorite-career-1-matches-container,
			.dashboard__top-favorite-career-2-matches-container,
			.dashboard__top-favorite-career-3-matches-container,
			.dashboard__top-matches-container {
				row-gap: 18px;
			}
		}
	}
}

.dashboard-careers-index {
	.dashboard__user-not-ready,
	.dashboard__offline {
		padding: 0px;
	}

	.date-filters {
		display: flex;
		flex-direction: row;

		> .input-wrapper {
			flex-basis: 50%;
			margin-right: 16px;
		}

		select {
			min-width: 125px;
		}

		.input-label {
			font-weight: normal;
			color: #aaa;
		}
	}

	.careers-summary-container {
		display: grid;
		grid-template-columns: auto 1fr;

		.careers-summary-options {
			display: none;
			background-color: #ffffff;
			padding: 16px;
		}

		.careers-summary {
			display: none;

			.lineage--career {
				background-color: rgba(255, 255, 255, .25);
			}

			.lineage--pathway {
				background-color: rgba(255, 255, 255, .5);
			}

			.lineage--cluster {
				background-color: rgba(255, 255, 255, .75);
			}

			.lineage--root {
				background-color: #ffffff;
			}
		}
	}

	.dashboard__match-container {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 16px;
	}

	.dashboard__match-container__header {
		margin-bottom: 16px;
	}

	.dashboard__match-container__stats {
		display: grid;
		grid-template-columns: 1fr auto auto;
		column-gap: 0px;
		row-gap: 0px;
		font-size: 14px;
	}

	.dashboard__match-container__favorites {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 0px;
		row-gap: 0px;
		font-size: 14px;
		text-align: center;
	}

	.dashboard__match-container__stats > *,
	.dashboard__match-container__favorites > * {
		padding: 7px 8px;
	}

	.dashboard__match-container__favorites > * {
		border-top: 1px solid #bbb;
	}

	.dashboard__careers-charts-loader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: #fff;
		opacity: 0.5;

		> img {
			position: relative;
			top: -300px;
			margin-top: 50vh;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.export-group {
		.save-button {
			width: auto;
		}

		ul.dropdown-menu {
			background-color: #1F2739;
			color: #ddd;

			li > a {
				font-size: 14px;
				color: #ddd;

				&:hover {
					background-color: #8300fd;
					background-image: none;
				}
			}
		}
	}

	table.app-table > thead > tr > th label {
		font-weight: normal;
		color: #888;
	}

	table.app-table > thead > tr > th,
	table.app-table > thead > tr > th label {
		font-size: 14px;
	}

	table.app-table > thead > tr > th,
	table.app-table > tbody > tr > td {
		text-align: left;
	}

	table.app-table > tbody > tr > td:first-of-type {
		white-space: normal;
	}
}

.dashboard-assessment-index,
.dashboard-coaching-index,
.dashboard-courses-index,
.dashboard-careers-index {
	.dashboard__step-stats__container {
		display: grid;
		grid-template-columns: min-content 1fr auto auto auto;
		row-gap: 12px;
		column-gap: 15px;
		font-size: 15px;
		white-space: nowrap;
		padding: 15px;
		background-color: #fff;

		.title {
			font-size: 12px;
		}
	}

	.dashboard__user-stats__container__toolbar {
		display: grid;
		grid-template-columns: auto 1fr;
		padding: 15px;
		align-items: center;
		background-color: #fff;

		.dashboard__view-mode {
			align-self: flex-start;
			align-items: center;
			margin-right: 15px;

			.dashboard__view-mode__summary,
			.dashboard__view-mode__detail {
				width: auto;
				display: none;
			}
		}

		.dashboard__last-loaded {
			align-self: flex-start;
			align-items: center;
		}
	}

	.dashboard__user-stats__container__filters,
	.dashboard__user-stats__container__filters .filter-row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;

		> .input-wrapper {
			//flex-basis: 25%;
			margin: 0px;
			margin-right: 24px;

			.input-label {
				font-weight: normal;
				color: #aaa;
			}

			&:last-of-type {
				margin-right: 0px;
			}
		}
	}

	.app-table {
		border-radius: 0px;

		thead > tr > th {
			word-wrap: break-word;
		}

		.fa-ellipsis {
			color: #1F2739;
		}

		.fa-minus {
			color: #aaa;
		}

		.fa-check,
		.fa-user-check {
			color: $success;
		}

		.fa-times,
		.fa-user-xmark {
			color: $danger;
		}

		.fa-book,
		.fa-user-clock,
		.fa-lock,
		.fa-question {
			color: #999;
		}

		.fa-book-open {
			color: $warning;
		}

		.fa-graduation-cap,
		.fa-award {
			color: $success;
		}

		.fa-check-circle {
			color: $success;
		}

		.fa-circle {
			color: $warning;
		}

		.fa-times-circle {
			color: $danger;
		}

		.score {
			font-size: 14px;
		}

		thead > tr > th,
		tbody > tr > td {
			text-align: center;

			.help-block,
			.coach-name,
			.meeting-time,
			.favorite-career,
			.take-action-path,
			.notes {
				display: inline-block;
				width: 100%;
			}

			.help-block,
			.favorite-career,
			.take-action-path,
			.notes {
				text-align: left;
				justify-self: start;
			}

			.favorite-career,
			.take-action-path {
				color: #aaa;
				font-size: 14px;
				max-width: 200px;

				&.not-selected {
					color: #aaaaaa50;
					font-style: italic;
				}
			}

			a.favorite-career,
			a.take-action-path {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			.coach-name {
				white-space: nowrap;
				text-align: right;
				justify-self: end;
			}

			.meeting-time {
				font-size: 14px;
				color: #999;
				text-align: right;
				justify-self: end;
			}

			.notes {
				font-size: 14px;
				color: #aaa;
			}

			.help-block {
				color: #777;
				font-size: 12px;
				max-width: 200px;
			}
		}

		thead > tr > th:first-of-type,
		tbody > tr > td:first-of-type {
			white-space: nowrap;
			text-align: left;
		}

		tbody > tr:hover {
			td {
				background-color: rgba(0, 0, 0, 0.1);
			}
		}

		tbody > tr.more-row {
			td:first-of-type {
				color: #aaa;

				.load-all,
				.load-all > a {
					color: #888;
				}
			}
		}

		.profile-picture {
			width: 32px;
			height: 32px;
			margin-right: 8px;
		}
	}

	.more-button {
		height: 28px;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		color: #aaa;
		border: 0px;
		border-radius: 4px;
		font-size: 14px;
	}
}

.dashboard-coaching-index,
.dashboard-courses-index {
	.dashboard__step-stats__container {
		grid-template-columns: min-content 1fr auto auto auto auto;
	}

	.dashboard__user-stats__container__toolbar {
		display: grid;
		grid-template-columns: auto auto 1fr;
	}
}

.dashboard-careers-index {
	.dashboard__user-stats__container__toolbar {
		display: block;

		.dashboard__controls {
			margin-left: auto;
			margin-bottom: 15px;
			display: flex;
			flex-direction: row;

			> div {
				margin-left: 8px;
			}

			button.advanced-search {
				background: none;
				border: none;
				color: #222;
				font-size: 14px;
			}

			.dashboard__exports {
			}
		}
	}

	.dashboard__last-loaded {
	}

	.dashboard__user-stats__container__filters {
		display: block;

		select {
			padding: 8px 6px;
			font-size: 14px;
			margin-bottom: 4px;

			option {
				font-size: 14px;
			}
		}

		.ui-multiselect {
			padding: 8px 6px;
			font-size: 14px;
			margin-bottom: 4px;
		}

		.filter-row {
			width: 100%;
			margin-bottom: 0px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin-bottom: 15px;

			&--advanced {
				flex-direction: row;
				flex-wrap: nowrap;

				&.hidden {
					display: none;
				}
				// Advanced option groups
				.filter-group {
					min-width: 225px;
					padding-right: 16px;

					.filter-label {
						.btn {
							text-align: start;
							padding: 12px;
							margin-top: 0px;
						}
					}

					> .filter-body {
						padding: 15px;
						background-color: rgba(0, 0, 0, 0.01);

						.input-wrapper {
							margin-bottom: 15px;
							margin-top: 0px;

							.fa-solid {
								font-size: 24px;
							}

							.input-label {
								font-weight: normal;
								color: #aaa;
							}
						}
					}

					select {
						width: 200px;
					}
				}
			}
		}

		.selected-account {
			max-width: 200px;
		}
	}

	.dashboard__careers-charts-container {
		table.app-table {
			tbody {
				tr {
					td {
						font-size: 13px;

						a {
							font-size: 13px;
						}

						img.profile-picture {
							width: 25px;
							height: 25px;
						}

						.profile-link {
							font-size: 15px;
						}
					}
				}
			}
		}
	}

	.dashboard__user-stats__container__filters {
		.filter-label {
			.filter-group-button {
				//background-color: #1F2739;
				//color: #fff !important;
				box-shadow: none;
				//border: 1px solid #444;

				&.has-values {
					color: #8300fd !important;
				}
			}
		}
	}
}

.panel-button-hover .panel-button-shine {
	position: relative;
	overflow: hidden;
	animation-duration: 0.25s;
}

.panel-button-hover .panel-button-shine::before {
	position: absolute;
	top: 0;
	left: -100%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.1) 100%);
	transform: skewX(-25deg);
}

.panel-button-hover .panel-button-shine:hover::before {
	animation: panel-button-shine 1s;
}

@keyframes panel-button-shine {
	100% {
		left: 100%;
	}
}

body.dark-theme {
	.dashboard-home-index,
	.dashboard-assessment-index,
	.dashboard-coaching-index,
	.dashboard-courses-index,
	.dashboard-careers-index {
		.dashboard__charts-filters {
			background-color: #2C3446;
		}

		.account-search-control {
			background-color: #2C3446;
		}

		.dashboard__charts-container {
			background-color: #2C3446;

			.dashboard__chart-container {
				.main-title {
					color: #eee;
				}

				.title {
					color: #ddd;
				}

				.substat__chart,
				.substat__lineChart {
					color: #aaa;
				}

				.dashboard__chart {
					background-color: rgba(0,0,0,0.1);
				}

				.dashboard__lineChart {
					background-color: rgba(0,0,0,0.1);
				}
			}
		}

		.dashboard__step-stats__container {
			color: #aaa;
			background-color: #2C3446;

			.title {
				color: #ddd;
			}
		}

		.dashboard__user-stats__container__toolbar {
			background-color: #2C3446;

			.dashboard__last-loaded {
				color: #777;
			}
		}

		.app-table {
			.fa-ellipsis {
				color: transparentize(#1F2739, 0.6);
			}

			.fa-minus {
				color: transparentize(#aaa, 0.6);
			}

			.fa-check,
			.fa-user-check {
				color: transparentize($success, 0.6);
			}

			.fa-times,
			.fa-user-xmark {
				color: transparentize($danger, 0.6);
			}

			.fa-book,
			.fa-user-clock,
			.fa-lock,
			.fa-question {
				color: transparentize(#999, 0.6);
			}

			.fa-book-open {
				color: transparentize($warning, 0.6);
			}

			.fa-graduation-cap,
			.fa-award {
				color: transparentize($success, 0.6);
			}

			.fa-check-circle {
				color: $success;
			}

			.fa-circle {
				color: $warning;
			}

			.fa-times-circle {
				color: $danger;
			}

			.score {
				color: #ccc;
			}

			thead > tr > th,
			tbody > tr > td {
				.favorite-career,
				.take-action-path {
					color: #aaa;

					&.not-selected {
						color: #aaaaaa50;
					}
				}

				.meeting-time {
					color: #999;
				}

				.notes {
					color: #aaa;
				}

				.help-block {
					color: #777;
				}
			}

			tbody > tr:hover {
				td {
					background-color: rgba(255, 255, 255, 0.1);
				}
			}

			tbody > tr.more-row {
				td:first-of-type {
					color: #aaa;

					.load-all,
					.load-all > a {
						color: #888;
					}
				}
			}
		}

		.search__input-wrapper {
			.search__input-icon {
				background-color: #1F2739;
				border: 0px;

				i.fa-solid {
					color: #ddd;
				}
			}
		}

		.search__results-container {
			background-color: #2C3446;
		}
	}

	.dashboard-home-index {
		.dashboard__career-charts-container {
			background-color: #2C3446;

			.take-action-path-title {
				color: #fff;
			}

			.dashboard__take-action-path-container {
				background-color: rgba(0, 0, 0, 0.1);

				a {
					.dashboard__take-action-path-container-column {
						.take-action-path-title {
							color: #a79fae;
						}
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.1);

						> * {
							background-color: rgba(255, 255, 255, 0.1);
						}
					}
				}
			}

			.dashboard__match-container {
				background-color: rgba(0, 0, 0, 0.1);

				.dashboard__top-favorite-career-matches-container {
					.favorite-header {
						color: #ddd;
					}
				}

				a {
					.match-title {
						color: #a79fae;
					}

					.match-percent {
						opacity: 0.8;
					}

					.match-number {
						color: #a79fae;
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.1);

						> * {
							background-color: rgba(255, 255, 255, 0.1);
						}
					}
				}
			}

			.dashboard__top-match-container {
				.match-header {
					color: #fff;
				}
			}
		}

		.dashboard__charts-container {
			.dashboard__chart-container {
				.dashboard__chart__link--assessment,
				.dashboard__chart__link--coaching,
				.dashboard__chart__link--courses {
					&:hover {
						.dashboard__chart,
						.dashboard__lineChart {
							background-color: rgba(255, 255, 255, 0.1);
						}
					}
				}
			}
		}
	}

	.dashboard-careers-index {
		.dashboard__careers-charts-loader {
			background-color: #333;
			opacity: 0.5;
		}

		.careers-summary-container {
			.careers-summary-options {
				background-color: #1e2432;
			}

			.careers-summary {
				.lineage--career {
					background-color: #1F273988;
				}

				.lineage--pathway {
					background-color: #1F2739aa;
				}

				.lineage--cluster {
					background-color: #1F2739cc;
				}

				.lineage--root {
					background-color: #1F2739;
				}
			}
		}

		.dashboard__match-container {
			background-color: rgba(0, 0, 0, 0.1);

			.dashboard__match-container__stats {
				.stat__match--overall {
					background-color: rgba(0, 0, 0, 0.2);
				}

				.stat__match--component {
					background-color: rgba(0, 0, 0, 0.1);
				}
			}

			.dashboard__match-container__favorites {
				border-top: 1px solid #333;

				.stat__favorite {
					background-color: rgba(0, 0, 0, 0.05);
				}
			}
		}

		.dashboard__user-stats__container__filters {
			.filter-label {
				.filter-group-button {
					background-color: #1F2739;
					color: #fff !important;
					box-shadow: none;
					border: 1px solid #444;

					&.has-values {
						color: #c88dff !important;
					}
				}
			}

			.filter-row--advanced .filter-group > .filter-body {
				background-color: rgba(255, 255, 255, 0.05);
			}
		}

		.dashboard__user-stats__container__toolbar {
			.dashboard__controls {
				button.advanced-search {
					color: #aaa;
				}
			}
		}

		.dashboard__careers-charts-container {
			table.app-table {
				tbody {
					tr {
						td {
							color: #ccc;

							a {
								color: #ccc;
							}
						}
					}
				}
			}
		}
	}
}

