﻿.app-refresh {
	position: sticky;
	top: 0px;
	left: 0px;
	width: 100%;
	text-align: right;
	align-items: center;
	align-content: center;
	background-color: #8300fd;
	color: #fff;
	padding: 16px;
	border-bottom: 1px solid lighten(#8300fd, 10%);

	button {
		background-color: transparent;
		border: none;
	}
}
