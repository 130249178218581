﻿.expandable-panel.panel {
	> .orientation-body {
		display: grid;
		grid-template-columns: 1fr 240px 1fr;
		column-gap: 50px;
		row-gap: 20px;
		padding: 0 50px 0 50px;

		> .info-box {
			background-color: #e8dcfb;
			border-radius: 10px;

			> .info-box-inner {
				padding: 15px;
				position: relative;
				top: 50%;
				transform: translate(0, -50%);

				p {
					text-align: center;
					font-weight: bold;
					font-size: 16px;
				}

				ul {
					font-size: 14px;
				}
			}
		}
	}
}

.OrientationPanelButton {
	.panel-button-shine {
		padding: 15px;

		img {
			box-shadow: black 3px 3px 10px;
		}
	}
}

.SelfManagementPanelButton, .CommunicateClearlyPanelButton, .TeamworkPanelButton, .ThinkingOnYourFeetPanelButton, .ProblemSolvingPanelButton {
	.panel-button-shine::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 220px;
		background-size: 100% 100%;
		border-radius: 8px;
	}
}

tbody.lms-course .course-icon {
	text-align: center;
	color: #888;
	font-size: 13px;

	p {
		margin: 0px;
	}
}

tbody.lms-lessons {
	> tr:nth-child(1) li {
		padding-bottom: 8px;
	}

	> tr:nth-child(2) td:nth-child(2) {
		border-radius: 5px 0px 0px 5px;
	}

	> tr:nth-child(2) td:nth-child(6) {
		border-radius: 0px 5px 5px 0px;
	}

	> tr:nth-child(even) > td {
		background-color: #eff9fc !important;
	}

	td:nth-child(1) {
		padding-left: 50px;
	}
}

.coachtraining-prereqs-section {
	.coachtraining-prereqs-stepnum {
		text-align: center;
		margin-bottom: 20px;

		div {
			text-align: center;
			box-sizing: border-box;
			background-color: mediumpurple;
			width: 50px;
			height: 50px;
			line-height: 50px;
			margin: auto;
			border-radius: 100%;
			box-shadow: grey 0px 0px 2px 1px;
			color: white;
			font-weight: bold;
			font-size: larger;
		}
	}

	.coachtraining-prereqs-content {
		position: relative;
		border: grey 1px solid;
		height: 500px;
		background: linear-gradient(transparent, mediumpurple);
		box-shadow: #505050 2px 2px 5px;
		padding: 15px;

		h4 {
			font-weight: bold;
			text-align: center;
			padding-bottom: 20px;
		}

		.assessment-progress {
			height: 95%;
			text-align: center;
		}

		.coachtraining-prereqs-footer {
			position: absolute;
			text-align: center;
			bottom: 10px;
			left: 0;
			font-weight: bold;
			width: 100%;
		}
	}

	&.active {
		.coachtraining-prereqs-stepnum {
			div {
				background-color: #d666dd;
			}
		}
	}

	&.disabled {
		filter: grayscale(100);
	}
}