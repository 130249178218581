﻿@import '../../variables/_screen-sizes';

.marketing-logo {
}

.marketing-nav-bar {
	z-index: 1;
	//z-index: 2147483647;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	overflow: visible;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #ffffff;
	border-style: solid;
	border-color: #EDE5FA;
	border-top-width: 0px;
	border-bottom-width: 1px;
	border-left-width: 0px;
	border-right-width: 0px;
}

.marketing-nav-bar-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.marketing-nav-bar-content-left {
	display: grid;
	grid-template-columns: repeat(6, max-content);
	grid-column-gap: 25px;
	align-items: center;
	text-transform: uppercase;
}

.marketing-nav-bar-content-right {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	grid-column-gap: 39px;
	align-items: center;
	margin-left: auto;
}

.marketing-nav-item {
	font-family: "Volte-Medium", "Volte", serif;
	color: #474747;
	font-size: 16px;
	letter-spacing: 0px;
	line-height: 1.3;
	font-weight: 500;
	text-align: center;

	a {
		text-decoration: none;
		font-size: 16px;

		&:focus, &:hover {
			text-decoration: none;
			color: #5C02B1;
		}
	}
}

.marketing-nav-item-login {
	font-family: "Volte-Medium", "Volte", serif;
	color: #232323;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 1.3;
	font-weight: 500;
	text-align: center;

	a {
		text-decoration: none;

		&:focus, &:hover {
			text-decoration: none;
			color: #5C02B1;
		}
	}
}

a.marketing-nav-item-signup-button {
	font-family: "Volte-Medium", "Volte", serif;
	height: 36px;
	width: 144px;
	color: #ffffff;
	background-color: #8300fd;
	border: 0px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px 0px #443d69;
	-webkit-appearance: button;
	-moz-appearance: button;
	appearance: button;
	text-decoration: none;
	text-align: center;
	padding-top: 9px;

	&:hover,
	&:focus {
		color: #ffffff;
		background-color: rgba(#8300fd, 0.8);
	}
}

@media (max-width: $phone-max) {
	.marketing-nav-bar-content {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.marketing-nav-bar-content-left {
		display: block;

		.marketing-nav-item {
			display: none;
		}
	}
}
