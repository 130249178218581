﻿.modal {
	.modal-dialog {
		.modal-content {
			.modal-header {
				.modal-title {
					display: inline-block;
				}

				button.close {
					font-size: 20px;
					text-shadow: none;
					font-weight: normal;
					min-height: unset;
					opacity: 1.0;
					color: #000000;
				}
			}
		}
	}
}

body.dark-theme {
	.modal {
		.modal-dialog {
			.modal-content {
				background-color: #323C50;

				.input-wrapper {
					margin-top: 16px;
				}

				.input-field,
				.form-control,
				.panel .input-field,
				.panel .form-control {
					background-color: rgba(255, 255, 255, 0.01);
					border: 1px solid #4c5c81;
					font-size: 14px;
					padding: 6px 8px;
					margin-bottom: 8px;
				}

				.input-label {
					font-weight: normal;
					font-size: 14px;
					//color: #aaa;
					color: #ddd;
				}

				button {
					min-height: 25px;
					width: auto;
					padding: 1px 6px;
					font-size: 13px;
				}

				label.checkbox-button {
					font-size: 12px;
				}

				.panel {
					background-color: transparent;
				}

				.modal-header {
					background-color: rgba(0, 0, 0, 0.2);
					border-bottom: 0px;
					padding-bottom: 10px;

					button.close {
						color: #ffffff;
					}

					.modal-icon {
						color: #ffffff;
					}

					.modal-title {
						color: #ffffff;
						font-size: 16px;
					}
				}

				.modal-body {
					color: #ddd !important;
				}

				.modal-footer {
					border-top: 0px;
				}

				.panel-body {
					h3 {
						font-size: 16px;
						margin-top: 0px;
						margin-bottom: 8px;
						padding-bottom: 8px;
						border-bottom: 1px solid #999;
					}
				}
			}
		}
	}
}