﻿body.admin-tools {
	.heading {
		display: grid;
		grid-template-columns: auto auto;
		margin-top: 16px;
		padding-bottom: 8px;
		border-bottom: 1px solid #999;

		h2 {
			margin: 0px;
		}

		> div:first-of-type {
			align-self: center;
			align-content: center;
			align-items: center;
		}

		> div:last-of-type {
			align-self: center;
			align-content: center;
			align-items: center;
			justify-self: flex-end;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 600;
	}

	h3 {
		font-size: 16px;
	}

	.input-wrapper {
		color: inherit;
		margin-top: 16px;
	}


	a.alt-button,
	a.back-button,
	a.cancel-button,
	a.remove-button,
	a.save-button,
	button,
	input[type="submit"] {
		width: auto;
		min-height: 25px;
		font-size: 13px;
		padding: 4px 8px;
	}

	.user-name-view {
		display: inline-block;
	}

	rolecontainer {
		color: inherit;
	}

	.search-result a,
	.search-additional-result {
		color: #aaa;
	}

	label.input-label,
	label.input-label {
		font-size: 12px;
		font-weight: normal;
	}

	.toggle-checkbox ~ div > label {
		font-size: 16px;
	}

	.modal {
		.modal-dialog {
			.modal-content {
				.input-wrapper {
					margin-top: 16px;
				}

				.input-field,
				.form-control,
				.panel .input-field,
				.panel .form-control {
					border: 1px solid #4c5c81;
					font-size: 14px;
					padding: 6px 8px;
					margin-bottom: 8px;
				}

				.input-label {
					font-weight: normal;
					font-size: 14px;
				}

				button {
					min-height: 25px;
					width: auto;
					padding: 1px 6px;
					font-size: 13px;
				}

				label.checkbox-button {
					font-size: 12px;
				}

				.panel {
					background-color: transparent;
				}

				.modal-header {
					border-bottom: 0px;
					padding-bottom: 10px;

					.modal-title {
						font-size: 16px;
					}
				}

				.modal-footer {
					border-top: 0px;
				}

				.panel-body {
					h3 {
						font-size: 16px;
						margin-top: 0px;
						margin-bottom: 8px;
						padding-bottom: 8px;
						border-bottom: 1px solid #999;
					}
				}
			}
		}
	}

	&.dark-theme {
		label.input-label,
		label.input-label {
			color: #aaa !important;
		}

		.toggle-checkbox ~ div > label {
			color: #fff !important;
		}

		rolecontainer {
			color: #fff;
		}

		.input-wrapper {
			color: #fff;
		}
	}
}
