﻿$normal-color: #9c62f8;
$warning-color: #8300fd;
$severe-color: #433e66;

#progress-time {
	text-align: center;

	.time-label {
		color: #666;
		font-size: 14px;
	}

	.time-value {
		font-weight: bold;
		font-size: 24px;

		.minutes,
		.seconds {
			background-color: $normal-color;
			color: #fff;
			border-radius: 4px;
			padding: 10px 20px 10px 20px;
			margin-bottom: 10px;

			.text {
				font-weight: normal;
				font-size: 12px;
			}
		}

		.minutes {
			display: inline-block;
		}

		.seconds {
			display: none;
		}
	}

	&.time-warning {
		.time-value {
			.minutes,
			.seconds {
				background-color: $warning-color;
			}
		}
	}

	&.time-severe {
		.time-value {
			.minutes,
			.seconds {
				background-color: $severe-color;
			}

			.seconds {
				display: inline-block;
			}
		}
	}
}

body.dark-theme {
	#progress-time {
		.time-label {
			color: #ddd;
		}
	}
}
