﻿.assessment-results {
	.results__personality,
	.results__abilities,
	.results__work-values,
	.save-button {
		display: none;
	}

	.results__header {
		display: grid;
		grid-template-columns: 5fr 1fr;
		align-items: flex-end;

		#interests-reviewed,
		#personality-reviewed,
		#abilities-reviewed,
		#work-values-reviewed {
			text-align: right;
			margin-bottom: 10px;
		}
	}

	.results__grid {
		display: grid;
		grid-template-columns: 1fr 2.25fr 1fr;
		grid-column-gap: 40px;
		background-color: #e8dcfc;
		padding: 25px;
		margin-bottom: 50px;
		width: 1122px;
	}

	.results__grid > div:nth-child(3) {
		background-color: rgb(171 139 179 / 35%);
		color: #181818;
		padding: 25px;
		border-radius: 8px;
	}

	.results__grid > div:nth-child(1) {
		background-color: transparent;
		padding: 25px;
		font-size: 13px;
		line-height: 1.8;
		font-style: italic;
	}

	h3 {
		margin-top: 0px;
		font-size: 30px;
		margin-bottom: 20px;
	}

	.personality-type-div {
		text-align: center;
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.interest-profile-type {
		text-align: center;
		font-size: 22px;
		font-weight: 600;
		color: #8300fd;
	}

	.interest-profile-type-subtext {
		text-align: center;
		color: #777;
		font-size: 12px;
	}

	.results__grid > div:nth-child(2) .boldCallOut {
		font-weight: normal;
	}

	.results__grid > div:nth-child(3) .boldCallOut {
		font-weight: 600;
		color: #181818;
	}

	.results__grid > div:nth-child(3) a {
		color: #181818;
		font-size: 16px;
	}

	.results__grid > div:nth-child(3) .popover {
		color: #222;
	}

	#interest-chart .highcharts-axis-labels text {
		cursor: pointer;
	}

	#interest-info div {
		display: none;
	}

	.wv-descriptions {
		display: none;
		margin-bottom: 25px;
	}

	#ability-info div p {
		line-height: 17px;
		font-size: 16px;
	}

	#ability-info > div {
		display: none;
	}

	li {
		line-height: 19px;
		margin-bottom: 10px;
	}

	.personality {
		margin-bottom: 25px;

		h2, h3 {
			text-align: center;
		}

		h3 {
			font-size: 20px;
		}
	}

	.personality__visual {
		margin-top: 5px;
		margin-bottom: 5px;

		.personality__heading {
			text-align: center;
			margin-bottom: 4px;

			.personality__heading__title {
				font-weight: bold;
				text-transform: uppercase;
				font-size: 14px;
				margin-top: 8px;
			}

			.personality__heading__subtitle {
				font-size: 12px;
				color: #777;
				line-height: 1.2;
			}
		}

		.personality__container {
			width: 100%;
			display: flex;

			.personality__container__legend {
				align-self: center;

				&--left {
					margin-right: 5px;
				}

				&--right {
					margin-left: 5px;
				}
			}

			.personality__container__legend__text {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				color: #fff;
				font-weight: bold;
				text-align: center;
				font-size: 14px;
				line-height: 25px;
				background-color: rgba(131, 0, 253, 0.25);

				&--selected {
					background-color: rgba(131, 0, 253);
				}
			}

			.personality__container__bar {
				display: inline-block;
				height: 35px;
				position: relative;
				background-color: rgba(131, 0, 253, 0.25);

				&--selected {
					background-color: rgba(131, 0, 253);
				}

				.personality__container__bar__score {
					font-size: 11px;
					background-color: #fff;
					color: #222;
					font-weight: bold;
					text-align: center;
					line-height: 25px;
					width: 25px;
					height: 25px;
					border-radius: 50%;
					position: absolute;
					top: 5px;
					z-index: 100000;

					&--left {
						left: 5px;
					}

					&--right {
						left: calc(100% - 30px);
					}
				}

				.personality__container__bar__text {
					font-size: 12px;
					margin-top: 35px;
					color: #444;
					font-weight: bold;
					width: 75px;
					padding: 3px 5px 3px 5px;
					z-index: 100000;

					&--left {
						text-align: left;
						float: left;
					}

					&--right {
						text-align: right;
						float: right;
					}
				}
			}
		}
	}

	.personality-type {
		padding: 8px;
		padding-bottom: 22px;
	}

	.personality-type.selected {
		background-color: rgba(171, 139, 179, 0.35);
	}

	.personality-type.reviewed {
		background-color: #433e66;

		.personality__heading .personality__heading__title,
		.personality__container__bar .personality__container__bar__text {
			color: #fff;
		}

		.personality__heading .personality__heading__subtitle {
			color: #ddd;
		}
	}

	.personality-type:hover {
		background-color: rgba(171, 139, 179, 0.35);
		opacity: 0.9;
		cursor: pointer;
	}

	#trait-details-wrapper > div {
		display: none;
	}

	.results__work-values__card {
		h3 {
			font-size: 20px;
			padding: 15px;
			margin: 0px;
			margin-bottom: 5px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&.results__work-values__primary h3 {
			background-color: #8300fd;
			color: #fff;
		}

		&.results__work-values__secondary h3 {
			background-color: #9b62f8;
			color: #fff;
		}

		.results__work-values__titles {
			padding: 15px;
		}

		.wv-title {
			color: #222;
			font-size: 16px;
			margin-bottom: 15px;
		}

		&.wv-reviewed {
			//border: 1px dotted #9c62f8;
			//background-color: #433e66;
			background-color: #fff;
			border: 1px solid #e8dcfc;

			.wv-title {
			}
		}

		&.wv-selected {
			background-color: rgb(156, 25, 255);
			border: 1px solid rgb(156, 25, 255);

			.wv-title {
				color: #fff;
			}
		}

		&:hover {
			border: 1px solid #9b62f8;
			opacity: 0.8;

			.wv-title {
			}
		}

		cursor: pointer;
		border-radius: 8px;
		background-color: #fff;
		border: 1px solid #e8dcfc;
	}


	.ability-steps {
		display: grid;
		grid-template-columns: repeat(8, 130px);
		grid-template-rows: 200px;
		justify-content: space-around;
		margin-top: 25px;

		.ability-step {
			display: grid;
			grid-template-rows: 50px 1fr 1fr;
			border: #aaa solid 1px;
			border-radius: 10px;
			box-shadow: 1px 1px 3px;
			text-align: center;
			align-items: center;
			filter: grayscale(1);

			&.completed {
				filter: none;
			}

			.ability-step-title {
				border-top: 1px solid #eee;
				border-bottom: 1px solid #eee;
				height: 100%;
				display: grid;
				align-items: center;
				padding: 0 10px;
				color: white;
				font-weight: bold;
			}

			.ability-step-number {
				height: 80%;
				background-color: lightblue;
				width: 32%;
				margin: 5px auto;
				border-radius: 100%;
				box-shadow: black 0 0 5px -1px;
				line-height: 40px;
				font-weight: bold;
				font-size: x-large;
			}

			.ability-step-footer {
				display: grid;
				grid-template-rows: 75% 25%;
				align-items: center;
				height: 100%;

				i.fa {
					font-size: 50px;
					color: #fdff71;
				}

				span {
					color: white;
					font-size: small;
				}
			}
		}
	}
}

body.dark-theme {
	.assessment-results {
		.results__grid {
			h2, h3, p, li {
				color: #222;
			}
		}
	}
}
