﻿@use "sass:color";

.final-report {
	width: 8.5in;
	max-width: 8.5in;

	.pdf-page {
		@page {
			size: letter portrait;
		}

		width: 8.5in;
		max-width: 8.5in;
		height: 11in;
		max-height: 11in;
		padding: 0px;
		break-before: page;
		margin: 0px;
		//border: 3px dotted red;
		position: relative;
	}

	.page-header {
		img {
			height: 100px;
		}
	}

	.page-heading {
		display: grid;
		grid-template-columns: 100px 1fr;
		grid-column-gap: 39px;
		align-items: flex-start;
		width: 100%;
		padding: 25px;

		.logo {
			filter: grayscale(100%) brightness(600%) opacity(50%);
			background-image: url('/wwwroot/Content/Images/marketing-logo.svg');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			height: 100px;
			width: 100px;
		}

		.subtitle {
			position: relative;
			text-transform: uppercase;
			font-size: 14px;
			border-bottom: 1px solid #fff;
			margin-bottom: 15px;
		}

		.subtitle::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			bottom: -5px;
			width: 100%;
			margin-top: 5px;
			border-bottom: 1px solid #fff;
		}

		.main-title {
			font-family: "Volte-Bold", "Volte", serif;
			font-weight: 600;
			font-size: 50px;
		}

		p {
			font-family: "Volte-Semibold", "Volte", serif;
			font-weight: 400;
		}

		&--cover-page {
			@extend .page-heading;
			background-color: #7814f5;
			color: #fff;
		}

		&--interest-profiler {
			@extend .page-heading;
			background-color: #76cff5;
			color: #fff;
		}

		&--ability-profiler {
			@extend .page-heading;
			background-color: #9466f2;
			color: #fff;
		}

		&--work-values {
			@extend .page-heading;
			background-color: #554ed4;
			color: #fff;
		}

		&--personality {
			@extend .page-heading;
			background-color: #ef77f5;
			color: #fff;
		}

		&--career-matches {
			@extend .page-heading;
			background-color: #f79a6a;
			color: #fff;
		}
	}

	.page-body {
		padding: 0px 20px;
	}

	.page-footer {
		display: grid;
		grid-template-columns: 1fr 1fr 25px;
		grid-column-gap: 15px;
		align-items: center;
		position: absolute;
		top: calc(11in - 37px);
		left: 0px;
		height: 35px;
		width: 100%;
		padding: 3px 10px 3px 10px;

		.page-footer__title {
			font-family: "Volte-Bold", "Volte", serif;
			font-weight: bold;
			font-size: 14px;
			line-height: 14px;
		}

		.page-footer__copyright {
			text-transform: uppercase;
			text-align: right;
			font-size: 12px;
			line-height: 12px;
		}

		.page-footer__page {
			font-family: "Volte-Bold", "Volte", serif;
			font-weight: bold;
			text-align: center;
			font-size: 14px;
			line-height: 14px;
		}

		&--interest-profiler {
			@extend .page-footer;
			background-color: #76cff5;
			color: #fff;
		}

		&--ability-profiler {
			@extend .page-footer;
			background-color: #9466f2;
			color: #fff;
		}

		&--work-values {
			@extend .page-footer;
			background-color: #554ed4;
			color: #fff;
		}

		&--personality {
			@extend .page-footer;
			background-color: #ef77f5;
			color: #fff;
		}

		&--career-matches {
			@extend .page-footer;
			background-color: #f79a6a;
			color: #fff;
		}
	}

	.circle {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		font-family: "Volte-Bold", "Volte", serif;
		font-weight: 600;
		font-size: 42px;

		&--cover-page {
			@extend .circle;
			background-color: #7814f5;
			white-space: nowrap;
			padding-top: 35px;
			padding-left: 50px;
			margin-top: 50px;
			margin-left: 15px;
		}

		&--interest-profiler,
		&--ability-profiler,
		&--work-values,
		&--personality {
			@extend .circle;
			display: inline-block;
			margin-bottom: -25px;
			text-align: center;
			padding-top: 21px;
		}

		&--interest-profiler {
			@extend .circle;
			background-color: #76cff5;

			&--0 {
				@extend .circle--interest-profiler;
				background-color: #76cff5;
			}

			&--1 {
				@extend .circle--interest-profiler;
				background-color: #76cff585;
			}

			&--2 {
				@extend .circle--interest-profiler;
				background-color: #76cff570;
			}

			&--3 {
				@extend .circle--interest-profiler;
				background-color: #76cff555;
			}

			&--4 {
				@extend .circle--interest-profiler;
				background-color: #76cff540;
			}

			&--5 {
				@extend .circle--interest-profiler;
				background-color: #76cff525;
			}
		}

		&--ability-profiler {
			@extend .circle;

			&--diagnostic-reasoning {
				@extend .circle--ability-profiler;
				background-color: #b89af6;
			}

			&--arithmetic-reasoning {
				@extend .circle--ability-profiler;
				background-color: #faa6c1;
			}

			&--vocabulary {
				@extend .circle--ability-profiler;
				background-color: #fbc6c9;
			}

			&--3d-spatial {
				@extend .circle--ability-profiler;
				background-color: #f8eee3;
			}

			&--computation {
				@extend .circle--ability-profiler;
				background-color: #d8e28b;
			}

			&--name-comparison {
				@extend .circle--ability-profiler;
				background-color: #eaefc0;
			}

			&--object-matching {
				@extend .circle--ability-profiler;
				background-color: #e5fbf4;
			}

			&--analytical-reasoning {
				@extend .circle--ability-profiler;
				background-color: #8aaee2;
			}
		}

		&--work-values {
			@extend .circle;
			background-color: #8f8ae2;

			&--0 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae2;
			}

			&--1 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae285;
			}

			&--2 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae270;
			}

			&--3 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae255;
			}

			&--4 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae240;
			}

			&--5 {
				@extend .circle--interest-profiler;
				background-color: #8f8ae225;
			}
		}

		&--personality {
			@extend .circle;
			background-color: #f4a5f8;
		}
	}

	.results {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 39px;
		grid-row-gap: 39px;
		margin-top: 25px;
		margin-bottom: 25px;
		margin-left: 15px;
		margin-right: 15px;
		font-size: 13px;

		p, ul li {
			font-size: 13px;
		}

		ul {
			padding-left: 0px;
			margin-left: 0px;
		}

		ul li {
			padding-left: 0px;
			margin-left: 8px;
			list-style-type: '- ';
		}

		&--interest-profiler,
		&--ability-profiler,
		&--work-values,
		&--personality {
			@extend .results;
		}

		&--ability-profiler {
			grid-row-gap: 25px;
		}

		&--personality {
			grid-template-columns: 2fr 1fr;

			&--sub {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 39px;
				grid-row-gap: 39px;
			}
		}
	}

	.result-title {
		position: relative;
		font-family: "Volte-Semibold", "Volte", serif;
		font-size: 22px;
		font-weight: 600;
		border-bottom: 1px solid #222;
		margin-bottom: 15px;
		display: inline-block;
		width: 50%;

		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			bottom: -5px;
			width: 100%;
			margin-top: 5px;
			border-bottom: 1px solid #222;
		}

		&--interest-profiler {
			@extend .result-title;
			border-bottom: 1px solid #76cff5;

			&::after {
				border-bottom: 1px solid #76cff5;
			}
		}

		&--ability-profiler {
			@extend .result-title;
			border-bottom: 1px solid #9466f2;

			&::after {
				border-bottom: 1px solid #9466f2;
			}
		}

		&--work-values {
			@extend .result-title;
			border-bottom: 1px solid #554ed4;

			&::after {
				border-bottom: 1px solid #554ed4;
			}
		}

		&--personality {
			@extend .result-title;
			border-bottom: 1px solid #ef77f5;

			&::after {
				border-bottom: 1px solid #ef77f5;
			}
		}
	}

	.decision-matrix {
		&.final-report__favorite-careers__container {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 8px;

			.job__summary__card .job__card__not__matchable {
				font-size: 10px;
			}

			.job__summary__card .job__card__icon .job__card__icon__text {
				font-size: 8px;
			}

			.job .job__summary__card .job__card__icon .job__card__icon__img {
				height: 20px;
			}

			.job .job__summary__card .job__card__icon.highlight .halo {
				height: 15px;
				width: 15px;
				top: 5px;
				margin-top: -5px;
				left: 20px;
				margin-left: -20px;
			}

			.job .job__summary__card--selected {
				border: none;
			}

			.job .job__summary__card .job__summary__card__title {
				font-size: 12px;
			}

			.job .job__summary__card {
				min-height: auto;
				padding: 8px;
				padding-bottom: 25px;
				background-color: color.scale(#fce2d5, $lightness: 75%);
				border: 1px solid #fce2d5;
			}
		}

		&.final-report__careers__container {
			.final-report__careers__row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 8px;
				grid-row-gap: 8px;
			}

			.job .job__card {
				grid-template-columns: unset;
				grid-template: "title wage" auto "icons icons" auto / 1.5fr 1fr;
				padding: 8px;
				margin-bottom: 8px;
				grid-row-gap: 8px;
				background-color: color.scale(#fce2d5, $lightness: 75%);
				border: 1px solid #fce2d5;
			}

			.job .job__card .job__card__chevron {
				display: none;
			}

			.job .job__card .job__title {
				font-size: 12px;
				grid-area: title;
			}

			.job .job__card .job__wage {
				font-size: 8px;
				grid-area: wage;
			}

			.job .job__card .job__wage__title {
				font-size: 8px;
			}

			.job .job__card .job__card__icons {
				grid-area: icons;
			}

			.job .job__card .job__card__not__matchable {
				font-size: 10px;
			}

			.job .job__card .job__card__icon .job__card__icon__text {
				font-size: 8px;
			}

			.job .job__card .job__card__icon .job__card__icon__img {
				height: 20px;
			}

			.job .job__card .job__card__icon.highlight .halo {
				height: 15px;
				width: 15px;
				top: 5px;
				margin-top: -5px;
				left: 20px;
				margin-left: -20px;
			}
		}
	}

	.summary-report {
		position: relative;

		.summary-report-heading {
			padding-top: 15px;
			padding-bottom: 15px;
			text-align: center;

			.embellishment {
				display: block;
				width: 300px;
				margin-left: auto;
				margin-right: auto;
			}

			h1 {
				text-transform: uppercase;
				font-family: "Volte-Bold", "Volte", serif;
				font-size: 25px;
				margin: 0px;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			h2 {
				font-size: 15px;
				font-weight: bold;
				margin: 0px;
			}
		}

		.iconified-headings {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 15px;
			margin-top: 20px;
			max-height: 150px;
		}

		.iconified-heading {
			display: grid;
			grid-template-columns: min-content auto;
			grid-column-gap: 8px;
			align-items: flex-start;
			justify-items: flex-start;

			.icon {
				height: 50px;
			}

			h2 {
				text-transform: uppercase;
				font-size: 16px;
				font-weight: bold;
				margin: 0px;
				margin-top: 8px;
			}

			p {
				margin-top: 3px;
				color: #777;
				font-size: 12px;
			}

			ul {
				margin: 0px;
				padding: 0px;
				padding-left: 15px;

				li {
					color: #777;
					font-size: 12px;
				}
			}
		}

		.visualization {
			.visualization__heading {
				h2 {
					text-align: center;
					text-transform: uppercase;
					font-size: 20px;
					font-weight: bold;
					margin: 0px;
				}

				p {
					text-align: center;
					color: #777;
				}
			}
		}

		.personality {
			margin-top: 25px;

			h3 {
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				margin: 0px;
			}
		}

		.personality__visual {
			margin-top: 5px;
			margin-bottom: 5px;

			.personality__heading {
				text-align: center;
				margin-bottom: 4px;

				.personality__heading__title {
					font-weight: bold;
					text-transform: uppercase;
					font-size: 12px;
					margin-top: 15px;
				}

				.personality__heading__subtitle {
					font-size: 8px;
					color: #777;
					line-height: 1.2;
				}
			}

			.personality__container {
				width: 100%;
				display: flex;

				.personality__container__legend {
					align-self: center;

					&--left {
						margin-right: 5px;
					}

					&--right {
						margin-left: 5px;
					}
				}

				.personality__container__legend__text {
					width: 15px;
					height: 15px;
					border-radius: 50%;
					color: #fff;
					font-weight: bold;
					text-align: center;
					font-size: 10px;
					line-height: 15px;
					background-color: rgba(131, 0, 253, 0.25);

					&--selected {
						background-color: rgba(131, 0, 253);
					}
				}

				.personality__container__bar {
					display: inline-block;
					height: 25px;
					position: relative;
					background-color: rgba(131, 0, 253, 0.25);

					&--selected {
						background-color: rgba(131, 0, 253);
					}

					.personality__container__bar__score {
						font-size: 7px;
						background-color: #fff;
						color: #222;
						font-weight: bold;
						text-align: center;
						line-height: 15px;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						position: absolute;
						top: 5px;
						z-index: 100000;

						&--left {
							left: 5px;
						}

						&--right {
							left: calc(100% - 20px);
						}
					}

					.personality__container__bar__text {
						font-size: 8px;
						color: #fff;
						margin-top: 25px;
						color: #444;
						font-weight: bold;
						width: 75px;
						padding: 3px 5px 3px 5px;
						z-index: 100000;

						&--left {
							text-align: left;
							float: left;
						}

						&--right {
							text-align: right;
							float: right;
						}
					}
				}
			}
		}

		.interests {
			padding-top: 175px;

			.interest-profile-type {
				color: rgba(131, 0, 253);
				text-align: center;
				font-weight: bold;
			}
		}

		.abilities {
			margin-top: 39px;
		}

		.summary-report-footer {
			position: absolute;
			top: calc(11in - 100px);
			left: calc(8.5in - 250px);

			img {
				height: 50px;
			}
		}
	}
}
