﻿@import '../../variables/_colors.scss';

.beta {
	.beta__label {
		cursor: pointer;
		margin-left: 5px;
		background-color: #8300fd;
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		vertical-align: middle;
		padding: 6px;
	}

	.beta__bug {
		background-color: transparent;
		border: none;
	}
}
