﻿.edu-listing__container {
	max-height: 450px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}

	a {
		word-break: break-all;
	}
}

