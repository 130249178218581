﻿// Registration

.registration {
	color: #47648b;

	label,
	input {
		color: #47648b;
	}

	h2 {
		margin-bottom: 25px;
	}

	h3 {
		margin-top: 0px;
	}

	h4 {
		font-weight: bold;
		margin: 0px;
	}

	.continue-button {
		background-color: #9775a8;
		color: #fff;
		border: 0px;
		border-radius: 4px;
		padding: 10px 30px 10px 30px;
		font-size: 14px;
	}

	input[type="password"],
	input[type="email"],
	input[type="text"],
	input[type="tel"],
	input[type="number"] {
		border: none;
		border-bottom: 2px solid #47648b;
		color: #47648b;
		font-size: 38px;
		text-align: center;

		&::placeholder {
			color: #ddd;
		}
	}

	input[type="number"] {
		width: 175px;
	}

	input[type="password"],
	input[type="email"],
	input[type="text"] {
		width: 100%;
	}

	textarea {
		border: none;
		border-bottom: 2px solid #47648b;
		color: #47648b;
		font-size: 28px;
		width: 100%;
		overflow-y: hidden;
	}

	select {
		border: none;
		border-bottom: 2px solid #47648b;
		color: #47648b;
		font-size: 38px;
		width: 100%;
		margin-top: 33px;

		option {
			font-size: 20px;
		}
	}

	label {
		color: #47648b;
		font-size: 20px;
		font-weight: normal;
		display: block;
	}

	.radio-button {
		cursor: pointer;
		padding-right: 25px;
		font-weight: normal;
		font-size: 24px;
		margin-bottom: 15px;
	}

	input[type=radio] {
		padding: 0;
		height: 0px;
		width: 0px;
	}

	input {
		font-size: 1rem;
		line-height: 1.5;
		padding: 11px 23px;
		border: 1px solid rgba(0,0,0,.15);
		border-radius: 0;
		outline: 0;
		background-color: transparent;
	}

	.radio-button__input {
		opacity: 0;
		position: absolute;
	}

	.radio-button__control {
		position: relative;
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 12px;
		vertical-align: middle;
		background-color: inherit;
		color: #47648b;
		border: 2px solid #47648b;
		border-radius: 24px;
	}

	.radio-button__input:checked + .radio-button__control:after {
		content: "";
		display: block;
		position: absolute;
		top: -2px;
		left: -2px;
		width: 24px;
		height: 24px;
		background-color: #47648b;
		border-radius: 24px;
	}

	.radio-button__input:checked + .radio-button__control {
		border-color: #47648b;
	}

	.radio-button__label {
		vertical-align: middle;
		line-height: 1.15em;
		color: #47648b;
	}

	.radio-button__price {
		font-weight: bold;
		padding-left: 8px;
		vertical-align: middle;
		line-height: 1.35em;
		color: #8a9cb5;
	}

	.radio-button__input:checked ~ .radio-button__label {
		font-weight: bolder;
	}

	.radio-button__input:checked ~ .radio-button__price {
		color: #47648b;
		font-weight: bolder;
	}

	.radio-button--course,
	.radio-button--included-course,
	.radio-button--all-courses {
		padding: 0px 12px;
		display: grid;
		grid-template-areas: "control label price" "empty benefit benefit";
		grid-template-columns: auto minmax(min-content, 1fr) minmax(min-content, 1fr);
		grid-template-rows: auto auto;
		align-items: flex-start;
		align-content: flex-start;
		margin-bottom: 25px;

		.radio-button__control {
			grid-area: control;
		}

		.radio-button__label {
			grid-area: label;
		}

		.radio-button__price {
			grid-area: price;
		}

		.radio-button__input:checked ~ .radio-button__price {
			color: #47648b;
		}

		.empty {
			grid-area: empty;
		}

		.course__benefit {
			grid-area: benefit;
			font-size: 18px;
			color: #888;
			line-height: 1.15em;
		}
	}

	.radio-button--all-courses {
		padding: 12px;
		background-color: #eee;
	}

	.radio-button--included-course {
		.radio-button__input:checked + .radio-button__control {
			visibility: hidden;
		}

		.radio-button__input:checked ~ .radio-button__label {
			font-weight: normal;
		}

		.radio-button__input:checked ~ .radio-button__price {
			color: #47648b;
			font-weight: normal;
		}
	}

	input.city {
		width: 100px;
		padding-right: 5px;
	}

	input.state {
		width: 80px;
		padding-left: 0px;
		padding-right: 0px;
	}

	input.zip {
		width: 130px;
		padding-left: 0px;
	}

	.cityStateZipComma {
		font-size: 38px;
		display: inline-block;
		vertical-align: bottom;
		padding-bottom: 5px;
		border-bottom: 2px solid #47648b;
		color: #47648b;
	}

	.title-bar {
		color: #fff;
		display: grid;
		grid-template-columns: 1fr 250px;

		&--main {
			font-weight: bold;
			font-size: 28px;
			padding: 25px;
		}

		&--sidebar {
			font-weight: normal;
			font-size: 14px;
			padding: 25px;
		}

		&--services-primary {
			background-color: lighten(#478b7f, 5%);
		}

		&--services-secondary {
			background-color: #478b7f;
		}

		&--organization-primary {
			background-color: lighten(#a0c6e1, 5%);
		}

		&--organization-secondary {
			background-color: #a0c6e1;
		}

		&--account-holder-primary {
			background-color: lighten(#97c7bf, 5%);
		}

		&--account-holder-secondary {
			background-color: #97c7bf;
		}

		&--payment-primary {
			background-color: lighten(#f1a2ee, 5%);
		}

		&--payment-secondary {
			background-color: #f1a2ee;
		}
	}

	.configuration-preview {
		font-size: 13px;
		padding: 15px;

		a {
			font-size: 13px;
		}

		.checkout-button {
			text-align: center;
			display: block;
			width: 100%;
			margin-top: 10px;
			text-decoration: none;
			background-color: #9775a8;
			color: #fff;
			border: 0px;
			border-radius: 4px;
			padding: 10px 30px 10px 30px;
		}

		&--services {
			background-color: #478b7f;
			color: #fff;

			a {
				color: #fff;
			}

			.checkout-button {
				background-color: lighten(#478b7f, 5%);
			}
		}

		&--organization {
			background-color: #a0c6e1;
			color: #fff;

			a {
				color: #fff;
			}

			.checkout-button {
				background-color: lighten(#a0c6e1, 5%);
			}
		}

		&--account-holder {
			background-color: #97c7bf;
			color: #fff;

			a {
				color: #fff;
			}

			.checkout-button {
				background-color: lighten(#97c7bf, 5%);
			}
		}

		&--payment {
			background-color: #f1a2ee;
			color: #fff;

			a {
				color: #fff;
			}

			.checkout-button {
				background-color: lighten(#f1a2ee, 5%);
			}
		}

		.configuration-preview__section {
			padding-bottom: 15px;
		}

		.configuration-preview__section:last-of-type {
			padding-bottom: 0px;
		}

		.configuration-preview__row {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 5px;
		}
	}

	.home-hero {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-auto-rows: 1fr;
		min-height: 300px;
		margin-bottom: 15px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;

		.home-hero__main {
			padding: 40px 20px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			background-color: #6f4887;
			color: #ffffff;

			.home-hero__main__title {
				font-weight: bold;
				font-size: 36px;
				margin-top: 0px;
			}

			.home-hero__main__description {
				margin-top: 15px;
				font-weight: 300;
				font-size: 20px;
				line-height: 1.6em;

				a {
					color: #ffffff;
				}
			}
		}

		.home-hero__image {
			background-image: url(/wwwroot/Content/Images/registration-hero.png);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top;
		}

		.home-hero__side {
			background-color: #6f4887;
			color: #eee;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.membership-type__container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 15px;
		margin-top: 25px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}

	.membership-type__panel {
		padding-bottom: 20px;
		border-radius: 4px;
		color: #ffffff;

		&--organizations {
			background-color: #6b818f;

			.membership-type__panel__heading {
				background-image: url(/wwwroot/Content/Images/purchase-col1-mask.png);
				background-repeat: no-repeat;
				background-position: top;
				background-size: cover;
			}
		}

		&--personal-groups {
			background-color: #973975;

			.membership-type__panel__heading {
				background-image: url(/wwwroot/Content/Images/purchase-col2-mask.png);
				background-repeat: no-repeat;
				background-position: top;
				background-size: cover;
			}
		}

		&--individuals {
			background-color: #47658b;

			.membership-type__panel__heading {
				background-image: url(/wwwroot/Content/Images/purchase-col3-mask.png);
				background-repeat: no-repeat;
				background-position: top;
				background-size: cover;
			}
		}

		.membership-type__panel__heading {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 35px;
			padding-right: 35px;
			min-height: 175px;
			font-size: 34px;
			font-weight: bold;
			line-height: 1.0;
		}

		.membership-type__panel__features {
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 35px;
			padding-right: 35px;
			margin-bottom: 20px;
			font-weight: 600;

			ul {
				padding: 0px;

				li {
					list-style: none;
					margin-bottom: 15px;
				}
			}

			a {
				color: #f5f5f5;
				font-size: 14px;
			}

			&--organizations {
				background-color: #a1c6e1;
			}

			&--personal-groups {
				background-color: #f0a2ee;
			}

			&--individuals {
				background-color: #97c7bf;
			}
		}

		.membership-type__panel__description {
			padding-left: 35px;
			padding-right: 35px;
			min-height: 160px;
			margin-bottom: 20px;
			font-weight: 300;

			a {
				color: #f5f5f5;
				font-size: 16px;
			}
		}

		.membership-type__panel__cta {
			display: block;
			padding: 10px 20px;
			width: 125px;
			margin-left: auto;
			margin-right: auto;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 18px;
			text-align: center;
			color: #ffffff;
			border-radius: 4px;

			&:hover,
			&:focus {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}
	}

	.invitation-box {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		.invitation-box__instructions {
			padding: 40px 20px 15px 20px;
		}

		.invitation-box__form-container {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-column-gap: 15px;
			align-items: center;
			align-content: center;
			background-color: rgba(255, 255, 255, 0.3);
			padding: 20px 20px;

			.input-field-helper {
				color: #fff;
				font-size: 14px;

				button {
					border: none;
					background-color: transparent;
					text-decoration: underline;
					color: #fff;
					font-size: 12px;
				}
			}
		}

		.alert {
			max-width: 200px;
		}

		input[type="text"] {
			margin-top: 10px;
			font-size: 20px;
			text-align: left;
			border-bottom: 1px solid #eee;
			color: #fff;

			&::placeholder {
				color: #ddd;
			}
		}

		input[type="submit"] {
			color: #ffffff;
			border: none;
			background-color: #6f4887;
			border-radius: 2px;
			padding: 10px 20px;
			font-size: 14px;

			&:hover,
			&:focus {
				background-color: #6f488799;
			}
		}

		.input-field-helper a {
			color: #fff;
		}
	}

	.input__prompt {
		color: #47648b;
		font-size: 20px;
		margin-bottom: 10px;
	}

	.service__description {
		font-size: 28px;
		color: #7889a0;
		margin-bottom: 25px;
		line-height: 1.15em;
	}

	.service__benefits {
		padding-bottom: 8px;
		padding-left: 20px;

		> li {
			font-size: 18px;
			line-height: 1.15em;
			margin-bottom: 8px;
		}
	}

	.payment__heading {
		font-size: 22px;
	}

	.payment__heading__subbar {
		margin-top: 15px;
		border-top: 3px solid #47648b;
		width: 100px;
		height: 10px;
	}

	.payment__subheading {
		font-size: 16px;
		margin-top: 35px;
	}

	.payment__grid {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 15px;
		margin-top: 25px;

		> div:first-of-type {
			text-align: right;
		}

		ul {
			margin-left: -15px;
		}
	}

	.payment__card__container {
		margin-top: 15px;
		margin-bottom: 50px;
	}

	.payment__card {
		background-color: #f1a2ee;
		padding: 8px;
	}

	.payment__security {
		margin-top: 5px;
		font-size: 14px;
		color: #bbb;
	}

	.payment__insight {
		margin-top: 25px;
		padding: 35px;
		text-align: center;
		background-color: #9b62f8;
		color: #fff;
	}

	.payment__insight__button {
		text-align: center;
		display: block;
		width: max-content;
		margin-top: 25px;
		margin-left: auto;
		margin-right: auto;
		text-decoration: none;
		background-color: #8300fd;
		color: #fff;
		border: 0px;
		border-radius: 4px;
		padding: 10px 30px 10px 30px;
	}

	.confirmation {
		table {
			width: 100%;

			tbody {
				tr {
					td {
						min-width: 50%;
						width: 50%;
						max-width: 50%;
						word-break: break-word;
						padding-bottom: 8px;
					}
				}
			}
		}

		h3 {
			margin-top: 25px;
		}

		h4 {
			margin-top: 25px;
			margin-bottom: 15px;
		}

		.invitation-code {
			margin-top: 15px;
			margin-bottom: 15px;
			background-color: #f1a2ee;
			color: #fff;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			margin-left: auto;
			margin-right: auto;
			padding: 10px 20px;
		}
	}

	.large-plan__hero {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-auto-rows: 1fr;
		margin-bottom: 15px;
		min-height: 300px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;

		.large-plan__hero__main {
			padding: 40px 20px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			background-color: #6f4887;
			color: #ffffff;

			.large-plan__hero__main__title {
				font-weight: bold;
				font-size: 36px;
				margin-top: 0px;
			}

			.large-plan__hero__main__description {
				margin-top: 15px;
				font-weight: 300;
				font-size: 20px;
				line-height: 1.6em;

				a {
					color: #ffffff;
				}
			}
		}

		.large-plan__hero__side {
		}

		.large-plan__hero__image {
			background-image: url(/wwwroot/Content/Images/purchase-col1-top.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top;
			background-position-y: -60px;
		}
	}

	.large-plan__container {
		margin-top: 25px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 15px;

		.large-plan__description,
		.large-plan__thank-you {
			font-size: 22px;
		}

		.large-plan__thank-you__image {
			background-image: url(/wwwroot/Content/Images/inquiry-thank-you.jpeg);
			background-position: top;
			background-size: contain;
			background-repeat: no-repeat;
			min-height: 600px;
		}
	}

	.large-plan__submit {
		display: block;
		margin-left: auto;
		background-color: #9775a8;
		color: #fff;
		border: 0px;
		border-radius: 4px;
		padding: 10px 30px 10px 30px;
		font-size: 18px;
		margin-top: 25px;
	}
}
