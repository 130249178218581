﻿.error-summary,
.validation-summary-errors {
	ul {
		margin: 0px;
		padding: 0px;
	}

	ul li {
		margin: 0px auto 25px auto;
		padding: 15px 15px 15px 85px;
		background-color: #9b62f8;
		list-style: none;
		color: #fff;
		font-family: "Volte", serif;
		display: block;
		width: max-content;
		min-width: 350px !important;
		border: 1px solid #9b62f8;
		border-radius: 4px;
		background-image: url(/wwwroot/Content/Images/notifications-failure.png), linear-gradient(to right, #9b62f8, #9b62f8);
		background-repeat: no-repeat, no-repeat;
		background-position: left, center;
		background-size: 82px, auto;
		background-position-x: 10px;
	}
}
