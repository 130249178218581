﻿.app-changes {
	display: grid;
	grid-template-columns: max-content max-content;
	grid-column-gap: 4px;
	align-items: center;
	align-content: center;
	justify-content: flex-end;
	background-color: #222;
	color: #eee;
	padding: 16px;
	border-bottom: 1px solid #444;

	button.modal-button,
	button.dismiss-button {
		background-color: transparent;
		border: none;
	}
}

#app-changes-modal {
	.modal-content {
		color: #eee;

		.modal-header {
			background-color: #a33fff;
			border: none;

			button.close {
				margin-top: 0px;
				color: #fff;
				text-shadow: none;
			}

			button.close > span {
				font-size: 20px;
				text-shadow: none;
			}

			.modal-title {
				color: #fff;
				font-weight: bold;
			}
		}

		.modal-body {
			color: #333;

			h4 {
				margin-top: 28px;
				font-size: 16px;
				color: #a33fff;
				text-transform: uppercase;
			}

			h5 {
				margin-top: 16px;
				font-size: 18px;
				color: #a33fff;
			}
		}

		.modal-footer {
			border: none;
			text-align: center;

			button.save-button {
				background-color: #a33fff;
				width: 100px;
			}
		}
	}
}

body.dark-theme {
	#app-changes-modal {
		.modal-content {
			background-color: #222;
			color: #eee;

			.modal-title {
				&::before {
					content: "";
					padding-right: 0px;
				}

				color: #eee;
				margin-bottom: 10px;
			}

			.modal-body {
				color: #bbb;

				h4 {
					color: #cb93ff;
				}

				h5 {
					color: #f1c7ff;
				}
			}
		}
	}
}
