﻿/* 

FP UI v.1 

NOTE:	Vertical distance between fields is 39px

*/

/*****************************************
*
*	Webfont kit
*
*****************************************/

@font-face {
	//font-family: 'voltebold';
	font-family: 'Volte-Bold';
	src: url('/wwwroot/fonts/volte_bold-webfont.woff2') format('woff2'), url('/wwwroot/fonts/volte_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	//font-family: 'voltelight';
	font-family: 'Volte-Light';
	src: url('/wwwroot/fonts/volte_light-webfont.woff2') format('woff2'), url('/wwwroot/fonts/volte_light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	//font-family: 'voltemedium';
	font-family: 'Volte-Medium';
	src: url('/wwwroot/fonts/volte_medium-webfont.woff2') format('woff2'), url('/wwwroot/fonts/volte_medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	//font-family: 'voltesemibold';
	font-family: 'Volte-Semibold';
	src: url('/wwwroot/fonts/volte_semibold-webfont.woff2') format('woff2'), url('/wwwroot/fonts/volte_semibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	//font-family: 'volteregular';
	font-family: 'Volte';
	src: url('/wwwroot/fonts/volte-webfont.woff2') format('woff2'), url('/wwwroot/fonts/volte-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('/wwwroot/fonts/Oswald-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

/*****************************************
*
*	Global
*
*****************************************/

body {
	font-family: "Volte", serif;
	font-size: 16px;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}
}

a {
	font-family: "Volte", serif;
	color: #4f4f4f;
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 1.2;
	text-decoration: underline;
}

.environment-banner {
	padding: 0px 20px 0px 20px;
	font-family: "Volte", serif;
	font-size: 16px;
	line-height: 50px;
	background-color: #8300fd90;
	color: #fff;
	position: fixed;
	top: calc(100vh - 50px);
	left: 0;
	width: 100%;
	height: 50px;
	z-index: 2147483647;

	.banner-logo {
		height: 25px;
		margin-top: 12px;
		margin-right: 10px;
		vertical-align: top;
	}

	.version-info {
		text-align: right;
		float: right;

		a {
			color: #fff;
		}
	}
}

.freshchat_widget {
	bottom: 80px !important;
}