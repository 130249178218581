﻿body.dark-theme label {
	color: #fff;
}

.label-fp {
	background-color: #433e66;
	color: #eee;
}

body.dark-theme .label-fp {
	background-color: #433e66;
	color: #eee;
}

help {
	font-size: 13px;
}

body.dark-theme help {
	color: #aaa;
}
