﻿body.dark-theme {
	.pagination > .active > a, 
	.pagination > .active > span, 
	.pagination > .active > a:hover, 
	.pagination > .active > span:hover, 
	.pagination > .active > a:focus, 
	.pagination > .active > span:focus {
		background-color: #323d4f;
		border-color: #323d4f;
		color: #fff !important
	}

	.pagination > li > a, 
	.pagination > li > span {
		color: #323e4e !important;
		background-color: #86869a !important;
		border: 1px solid #86869a !important;
	}

	.pagination > .disabled > span, 
	.pagination > .disabled > span:hover, 
	.pagination > .disabled > span:focus, 
	.pagination > .disabled > a, 
	.pagination > .disabled > a:hover, 
	.pagination > .disabled > a:focus {
		background-color: #868699 !important;
		border-color: #868699 !important;
	}
}
