﻿.notification-side-modal {
	position: fixed;
	z-index: 9999;
	width: 255px;
	height: 400px;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 4px;
	border: 1px solid #ddd;
	overflow-x: hidden;
	overflow-y: auto;
	opacity: 0;
	animation-duration: 0.25s;
	user-select: none;

	h2 {
		font-size: 14px;
		font-weight: bold;
		margin: 0px;
		padding: 10px;
		width: 100%;
		background-color: #292929;
		color: #fff;
	}

	.notification-items {
		min-height: 325px;
	}

	.notification-item {
		display: grid;
		grid-template-columns: 40px auto;
		border-bottom: 1px solid #aaa;
		cursor: pointer;

		&:hover {
			background-color: #8300fd;
			color: #fff;

			> .notification-envelope {
				> i {
					&.fa-envelope {
						color: #fff;
					}

					&.fa-envelope-open-text {
						color: #ddd;
					}
				}
			}
		}

		> .notification-envelope {
			text-align: center;

			> i {
				font-size: x-large;
				transform: translate(0, 65%);
				color: #8300fd;

				&.fa-envelope-open-text {
					color: #777;
				}
			}
		}
	}

	.notification-text-holder {
		display: table;
		position: relative;
		width: 100%;
		height: 60px;
		padding: 10px 10px 10px 0;

		> .notification-header {
			display: table-cell;
			height: 40px;
			line-height: 20px;
			font-size: 13px;
			vertical-align: middle;
		}

		> .notification-sent-date {
			display: inline;
			position: absolute;
			height: 15px;
			font-size: x-small;
			right: 3px;
			bottom: 3px;
		}
	}

	> .notification-mark-all-read {
		&.clickable {
			cursor: pointer;

			&:hover {
				color: black !important;
			}
		}

		> i {
			&.hidden {
				display: none;
			}
		}
	}

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}
}
