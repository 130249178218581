﻿application-feature-opt-in-out {
	width: 100%;
	display: flex;
	padding: 4px 8px;
	margin-bottom: 4px;
	align-items: center;
	align-content: center;
	justify-content: flex-end;
	text-align: right;
	background-color: #292929;
	color: #aaa;
	border-bottom: 1px solid lighten(#292929, 10%);

	> div {
		padding-right: 8px;
	}
}

body.dark-theme application-feature-opt-in-out {
	background-color: #00000033;
	color: #fff;
	border-bottom: 1px solid lighten(#00000030, 10%);
}
