﻿.app-issues {
	display: grid;
	grid-template-columns: max-content max-content;
	grid-column-gap: 4px;
	align-items: center;
	align-content: center;
	justify-content: flex-end;
	background-color: #b52879;
	color: #eee;
	padding: 16px;
	border-bottom: 1px solid lighten(#b52879, 10%);

	button.modal-button,
	button.dismiss-button {
		background-color: transparent;
		border: none;
	}
}

#app-issues-modal {
	.modal-content {
		color: #eee;

		.modal-header {
			background-color: #a33fff;
			border: none;

			button.close {
				margin-top: 0px;
				color: #fff;
				text-shadow: none;
			}

			button.close > span {
				font-size: 20px;
				text-shadow: none;
			}

			.modal-title {
				color: #fff;
				font-weight: bold;
			}
		}

		.modal-body {
			color: #333;

			h4 {
				margin-top: 28px;
				font-size: 16px;
				color: #a33fff;
				text-transform: uppercase;
			}

			h5 {
				margin-top: 16px;
				font-size: 18px;
				color: #a33fff;
			}

			.issue {
				margin-bottom: 35px;
				background-color: rgba(0, 0, 0, 0.05);

				&:last-of-type {
					margin-bottom: 0px;
				}

				.issue__header {
					padding: 8px;
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 15px;
					grid-row-gap: 15px;

					h5 {
						margin: 0px;
					}

					.issue__metadata {
						display: grid;
						grid-template-columns: max-content max-content;
						grid-column-gap: 15px;
						grid-row-gap: 8px;

						h6 {
							margin: 0px;
						}
					}
				}

				.issue__description {
					padding: 8px;

					h6 {
						margin: 0px;
					}
				}

				.issue__workaround {
					margin-top: 15px;
					background-color: #e3d5f9;
					padding: 8px;

					h6 {
						margin: 0px;
					}
				}
			}
		}

		.modal-footer {
			border: none;
			text-align: center;

			button.save-button {
				background-color: #a33fff;
				width: 100px;
			}
		}
	}
}

body.dark-theme {
	#app-issues-modal {
		.modal-content {
			background-color: #222;
			color: #eee;

			.modal-title {
				&::before {
					content: "";
					padding-right: 0px;
				}

				color: #eee;
				margin-bottom: 10px;
			}

			.modal-body {
				color: #bbb;

				h4 {
					color: #cb93ff;
				}

				h5 {
					color: #f1c7ff;
				}

				.issue {
					background-color: rgba(255, 255, 255, 0.1);

					.issue__workaround {
						background-color: #8875a5;
						color: #eee;
					}
				}
			}
		}
	}
}
