﻿@import '../../variables/screen-sizes';

$map-lg: 1670px;
$map-md: 1400px;
$map-sm: 1200px;

.decision-matrix {
	.instruction__main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		margin-bottom: 25px;

		.instruction__main__title {
			font-weight: bold;
			font-size: 1.25em;
		}
	}

	.instruction__interest {
		margin-bottom: 25px;

		.instruction__interest__title {
			font-weight: bold;
			font-size: 1.25em;
		}

		.instruction__interest__instructions {
			width: 50%;
		}
	}

	.career__favorite__placeholder {
		background-color: #fafafa;
		border-radius: 8px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding: 25px;

		.career__favorite__placeholder__title {
			font-size: 28px;
			color: #999;
		}

		.career__favorite__placeholder__text {
			color: #999;
		}
	}

	.career__interest__placeholder {
		background-color: #fafafa;
		border-radius: 8px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding: 25px;

		.career__interest__placeholder__title {
			font-size: 28px;
			color: #999;
		}

		.career__interest__placeholder__text {
			color: #999;
		}
	}

	.career__favorite__matches {
		margin-bottom: 39px;
	}

	.career__interest__matches {
		margin-bottom: 35px;
	}

	.career__interest__favorite {
		height: 25px;
		width: 25px;
		border: 1px solid #cfb1fd;
		border-radius: 50%;
		text-align: center;

		&:hover {
			cursor: pointer;
			background-color: #9b62f8;
			color: #fff;
		}
	}

	.career__interest__favorite--selected {
		background-color: #8300fd;
		color: #fff;
	}

	.career__interest__remove {
		align-self: flex-start;
		text-align: center;
		color: #aaa;

		.career__interest__remove__glyph {
			height: 25px;
			width: 25px;
			border: 1px solid #aaa;
			border-radius: 50%;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}

		.career__interest__remove__text {
			font-size: 10px;
			margin-top: 8px;
		}

		&:hover {
			cursor: pointer;

			.career__interest__remove__glyph {
				background-color: #9b62f8;
				color: #fff;
			}

			.career__interest__remove__text {
				color: #222;
			}
		}
	}

	.career__search__container {
		.career__search {
			margin-top: 50px;
			margin-bottom: 50px;

			.input-wrapper {
				margin-top: 15px;
			}

			.career__search__title {
				font-weight: bold;
				font-size: 1.25em;
			}

			button.career__search__options__advanced {
				width: 300px;
			}

			button.career__search__options__default {
				width: 200px;
				margin-right: 10px;
			}

			.career__search__options__container {
				display: none;

				.career__search__selects {
					display: grid;
					grid-template-columns: 2fr repeat(9, 1fr) 1fr 2fr;
					grid-column-gap: 10px;

					label {
						font-weight: normal;
					}
				}
			}

			.career__search__button {
				display: flex;
				justify-content: flex-end;

				.save-button {
					width: 200px;
				}
			}
		}
	}

	.job {
		.job__summary__card,
		.job__card {
			padding: 15px;
			margin-bottom: 15px;
			display: grid;
			grid-template-columns: 2fr 70px 2fr 350px 50px;
			grid-gap: 15px;
			background-color: #faf9fd;
			align-items: center;

			.job__title {
				font-weight: bold;
				font-size: 1.1em;
			}

			.job__wage__title {
				font-weight: bold;
				font-size: 0.8em;
			}

			.job__card__icons {
				display: flex;
				justify-content: space-around;
			}

			.job__card__not__matchable {
				font-size: 0.9em;
				text-align: center;
			}

			.job__card__icon {
				text-align: center;

				.job__card__icon__img {
					height: 35px;
				}

				.job__card__icon__text {
					font-size: 0.7em;
					text-align: center;
				}

				.halo {
					display: none;
				}

				&.highlight {
					z-index: 99;

					.halo {
						height: 25px;
						width: 25px;
						border-radius: 50%;
						background-color: rgb(131 0 253 / 30%);
						display: inline-block;
						position: relative;
						top: 10px;
						margin-top: -10px;
						left: 33px;
						margin-left: -33px;
						z-index: -1
					}
				}
			}

			.job__card__chevron,
			.job__summary__card__chevron {
				display: flex;
				justify-content: flex-end;
				padding-right: 20px;
				color: #444;
				cursor: pointer;
			}
		}

		.job__summary__card {
			display: block;
			margin-bottom: 15px;
			border-radius: 8px;

			.job__summary__card__title {
				font-weight: bold;
				color: #333;
				text-decoration: none;

				&:hover {
					color: #333;
					text-decoration: underline;
				}
			}

			.job__summary__card__chevron {
				align-items: center;
			}
		}

		.job__summary__card--selected {
			/*border: 3px solid #8300fd;*/
		}

		.job__summary__mini-card {
			padding: 15px;
			margin-bottom: 15px;
			background-color: #faf9fd;
			display: flex;
			flex-direction: column;
			min-height: 125px;
			height: auto;
			justify-content: space-between;

			.job__title {
				font-weight: bold;
				font-size: 1.0em;
				color: #333;
				text-decoration: none;

				&:hover {
					color: #333;
					text-decoration: underline;
				}
			}

			.job__wage__title {
				font-weight: bold;
				font-size: 0.8em;
			}

			.job__education-level__title {
				font-size: 0.8em;
			}

			.job__card__icons {
				align-self: flex-end;
				display: flex;
				justify-content: space-around;
				width: 100%;
			}

			.job__card__not__matchable {
				font-size: 0.7em;
				text-align: center;
			}

			.job__card__icon {
				text-align: center;

				.job__card__icon__img {
					height: 25px;
				}

				.job__card__icon__text {
					font-size: 0.7em;
					text-align: center;
					display: none;
				}

				.halo {
					display: none;
				}

				&.highlight {
					z-index: 99;

					.halo {
						height: 15px;
						width: 15px;
						border-radius: 50%;
						background-color: rgb(131 0 253 / 30%);
						display: inline-block;
						position: relative;
						top: 6px;
						margin-top: -6px;
						left: 23px;
						margin-left: -23px;
						z-index: -1
					}
				}
			}

			.job__card__chevron {
				display: flex;
				justify-content: flex-end;
				padding-right: 20px;
				color: #444;
				cursor: pointer;
			}
		}

		.job__card {
			.job__wage {
				display: grid;
				grid-template-columns: min-content auto;
				grid-column-gap: 5px;
			}
		}

		.job__detail__container {
			.maps {
				display: block;
				margin-top: 25px;

				@media (min-width: $map-md) and (max-width: $map-lg) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 50px;
					margin-top: 25px;
				}
			}

			.fitness-heading {
				display: grid;
				grid-template-columns: repeat(2, max-content);
				align-items: center;

				@media (max-width: $map-lg) {
					margin-top: 25px;
					grid-gap: 25px;
				}

				@media (min-width: $map-lg) {
					display: grid;
					grid-template-columns: 2fr 1fr;
				}
			}

			.fitness-charts {
				display: block;

				@media (max-width: $map-sm) {
					display: block;
				}

				@media (min-width: $map-sm) and (max-width: $map-md) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 50px;
				}

				@media (min-width: $map-md) and (max-width: $map-lg) {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 50px;
				}
			}

			.map-container,
			.edu-map-container {
				@media (min-width: $map-md) {
					max-width: $map-md / 2;
				}

				@media (min-width: $map-lg) {
					max-width: $map-lg / 2;
				}
			}

			.map,
			.edu-map {
				@media (min-width: $map-md) {
					max-width: $map-md / 2;
				}

				@media (min-width: $map-lg) {
					max-width: $map-lg / 2;
				}
			}

			.match-abilities-chart,
			.match-interests-chart,
			.match-work-interests-chart {
				@media (min-width: $map-md) {
					max-width: $map-md / 3;
				}

				@media (min-width: $map-lg) {
					max-width: $map-lg / 2;
				}
			}

			.job__detail {
				@media (min-width: $map-lg) {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 39px;
				}

				padding: 39px;
				padding-top: 0px;

				h2 {
					font-size: 1.1em;
					font-weight: bold;
				}

				.career-interest {
					margin-top: 25px;
					margin-bottom: 25px;
				}

				.save-to-favorites-button,
				.interested-button {
					width: 200px;
				}

				.explore-button {
					margin-left: 15px;
					width: 200px;
				}

				.job_openings,
				.edu_programs {
					margin-top: 25px;

					.job-totals,
					.edu-totals {
						display: grid;
						grid-template-columns: repeat(2, 2fr) 1fr;
					}

					.job_openings__row,
					.edu_programs__row {
						display: grid;
						grid-template-columns: 25px auto;
						grid-column-gap: 5px;
						margin-bottom: 15px;

						.job_openings__title,
						.edu_programs__title {
							font-weight: bold;
						}
					}
				}

				.work-values {
					display: none;
				}

				.educational-requirements {
					display: grid;
					grid-template-columns: 35px auto;
					grid-column-gap: 15px;

					span {
						margin-top: 5px;
					}

					img {
						height: 35px;
					}
				}

				.progress {
					border-radius: 0px;
					background-color: #dfdfdf;
					background-image: none;
					text-align: right;
					font-size: 12px;
					color: #fff;
					margin-bottom: 0px;

					.progress-bar {
						background-color: rgba(255, 255, 255, 0.5);
						background-image: none;
						height: 100%;

						&--very-strong {
							@extend .progress-bar;
							background-color: rgba(64, 255, 143, 0.5);
						}

						&--strong {
							@extend .progress-bar;
							background-color: rgba(255, 235, 87, 0.5);
						}

						&--not-strong {
							@extend .progress-bar;
							background-color: rgba(255, 54, 0, 0.5);
						}
					}
				}

				.progress-helper {
					color: #aaa;
					font-size: 11px;
					margin: 0px;
				}

				.match-chart-container {
					margin-top: 15px;
					margin-bottom: 15px;
				}

				.not-matchable-notification {
					border: 1px solid #e500e1; 
					border-radius: 4px; 
					padding: 8px; 
					background-color: #e500e150;

					p {
						color: #333;
					}
				}
			}
		}
	}

	#CareerMatchInstructionModal ul li {
		margin-top: 10px;
	}
}

body.dark-theme {
	.decision-matrix .job .job__summary__mini-card .job__title,
	.decision-matrix .job .job__summary__card .job__summary__card__title {
		color: #fff;
	}

	.decision-matrix .job .job__summary__mini-card .job__title:hover,
	.decision-matrix .job .job__summary__card .job__summary__card__title:hover {
		color: #aaa;
		text-decoration: none;
	}

	.decision-matrix .job .job__summary__card,
	.decision-matrix .job .job__card,
	.decision-matrix .job .job__summary__mini-card {
		background-color: transparent;
		border: 1px solid #a7a0ae;

		.job__card__chevron,
		.job__summary__card__chevron {
			filter: invert(1);
		}
	}

	.decision-matrix .job .job__summary__card.warnings,
	.decision-matrix .job .job__summary__mini-card.warnings {
		background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent) !important;

		> div:first-child {
			color: #fff !important;
		}
	}

	.decision-matrix .career__interest__placeholder,
	.decision-matrix .career__favorite__placeholder {
		background-color: rgba(0, 0, 0, 0.1);
		border: 1px solid #a7a0ae;
	}

	.decision-matrix .career__interest__placeholder .career__interest__placeholder__title {
		color: #fff;
	}

	.decision-matrix .job .job__card .job__card__icon .job__card__icon__img,
	.decision-matrix .job .job__summary__card .job__card__icon .job__card__icon__img,
	.decision-matrix .job .job__summary__mini-card .job__card__icon .job__card__icon__img {
		filter: brightness(2);
	}

	.decision-matrix .job .job__card .job__card__icon.highlight .halo,
	.decision-matrix .job .job__summary__card .job__card__icon.highlight .halo,
	.decision-matrix .job .job__summary__mini-card .job__card__icon.highlight .halo {
		background-color: #8300fd80;
	}

	.decision-matrix .job .job__detail__container .job__detail .not-matchable-notification {
		p {
			color: #eee;
		}
	}
}
