﻿.assessment-intro {
	display: grid;
	grid-template-columns: 1fr;
	background-color: #e8dcfc;
	padding: 27px;

	body {
		font-family: "Volte", serif;
		background-color: rgba(237, 229, 250, 0.5);
	}

	.cb__list {
		padding: 0;
		list-style-type: none;
		font-weight: 500;
		width: 560px;
		overflow: hidden;

		.bubble {
			position: relative;
			text-align: left;
			padding: 20px 30px;
			line-height: 1.3em;
			background-color: #fff;
			border: 1px solid transparent;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			border-radius: 8px;
		}

		.bubble.typing {
			animation: cb-font-fade 1.2s ease-in-out infinite;
		}

		.bubble-tail {
			width: 0;
			height: 0;
			position: relative;
			left: 20px;
			top: -5px;
			border-left: 30px solid white;
			border-bottom: 30px solid transparent;
			margin-bottom: 5px;
		}
	}

	.user-confirmation-template__bubble {
		background-color: white; 
		border-radius: 10px; 
		padding: 10px;
	}

	.user-confirmation-template__tail {
		width: 0; 
		height: 0; 
		position: relative; 
		right: -80%; top: -5px; 
		border-right: 30px solid white; 
		border-bottom: 30px solid transparent;
	}

	@keyframes cb-font-fade {
		50% {
			color: transparent;
		}

		100% {
			color: "inherit";
		}
	}
}

body.dark-theme {
	.assessment-intro {
		background-color: rgba(0, 0, 0, 0.1);

		.cb__list {
			.bubble {
				background-color: rgba(0, 0, 0, 0.2);
			}

			.bubble-tail {
				border-left-color: rgba(0, 0, 0, 0.2);
			}
		}

		.user-confirmation-template__bubble {
			background-color: rgba(0, 0, 0, 0.2);
		}

		.user-confirmation-template__tail {
			border-right-color: rgba(0, 0, 0, 0.2);
		}
	}
}
