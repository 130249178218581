﻿$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/_mixins';
@import '~@fortawesome/fontawesome-free/scss/_variables';
@import '~@fortawesome/fontawesome-free/scss/_icons';

.role-icon--default {
	@include fa-icon-regular($fa-var-user);
}

.role-icon--student {
	@include fa-icon-solid($fa-var-child);
}

.role-icon--parent {
	@include fa-icon-solid($fa-var-user-group);
}

.role-icon--coach,
.role-icon--coachinternal {
	@include fa-icon-solid($fa-var-business-time);
}

.role-icon--coachexternal {
	@include fa-icon-solid($fa-var-user-tie);
}

.role-icon--superadmin,
.role-icon--readonlyadmin {
	@include fa-icon-solid($fa-var-gears);
}

.role-icon--districtadmin,
.role-icon--schooladmin {
	@include fa-icon-solid($fa-var-gear);
}

.role-icon--admin,
.role-icon--accountholder,
.role-icon--accountcontactowner {
	@include fa-icon-solid($fa-var-user-gear);
}

.role-icon--student,
.role-icon--default {
	color: #9c62f8;
}

.role-icon--coach,
.role-icon--coachinternal,
.role-icon--coachexternal {
	color: #39a383;
}

.role-icon--admin,
.role-icon--accountholder,
.role-icon--superadmin,
.role-icon--readonlyadmin,
.role-icon--districtadmin,
.role-icon--schooladmin {
	color: #c5407a;
}

.role-icon--parent {
	color: #aaa;
}
