﻿navcontainer {
	position: fixed;
	left: 0;
	width: 100%;
	padding: 8px;
	background-color: #292929;
	color: #aaa;
	z-index: 100000;

	&:not(.top) {
		bottom: 0;
	}

	&.top {
		top: 0;
	}

	&.env--local,
	&.env--dev,
	&.env--stage,
	&.env--demo,
	&.top {
		background-color: #a33fff;
		color: #fff;

		a,
		navlogo > site,
		navicon > icon,
		navtext {
			color: #fff;
		}

		navicon:hover > icon,
		navicon:focus > icon,
		navicon.selected > icon {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	navlayout {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
	}
}
