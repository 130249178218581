﻿.user-email-view,
.user-name-view {
	display: inline;

	&.disabled {
		text-decoration: line-through;
	}
}

.user-email-view,
.user-name-view,
.user-email-view > a,
.user-name-view > a {
	font-weight: bold;
}

.user-email-view > a,
.user-name-view > a {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}