@import '../vendor/jquery.baraja/baraja.css';
@import '~jquery-ui-themes/themes/eggplant/jquery-ui.css';
@import '~jquery-ui.combobox/lib/jquery-ui.combobox.css';
@import '~jquery-ui-multiselect-widget/css/jquery.multiselect.css';
@import '~jquery-ui-multiselect-widget/css/jquery.multiselect.filter.css';
@import '~chosen-js/chosen.css';

// TODO: Switch to @use statements at some point

// Global
@import 'global';

// Layouts
@import 'layouts/app';
@import 'layouts/account';
@import 'layouts/error';
@import 'layouts/public';
@import 'layouts/review';
@import 'layouts/student-report';

// Components
@import 'components';

// Pages
@import 'pages';

// Tag Helpers
@import 'tag-helpers';
