﻿@import '../../variables/_screen-sizes';

.app-container {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: auto;
	margin-right: auto;
	overflow-y: hidden;

	a {
		font-size: 16px;
	}
}

body.dark-theme .app-container {
	background: #1F2739;
}

.app-container-no-nav {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: auto;
	margin-right: auto;
	overflow-y: hidden;

	a {
		font-size: 16px;
	}
}

.inside-app-frame {
	min-height: 100vh;
	height: 100vh;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}
}

body.dark-theme .inside-app-frame {
	background: #1F2739;
}

.inside-app-container {
	min-height: 100vh;
	padding-bottom: 50px;
	background-color: rgba(237, 229, 250, 0.5);
	width: 100%;
}

body.dark-theme .inside-app-container {
	background-color: #323C50;
}

.inside-app-container-no-nav {
	min-height: 100vh;
	padding-bottom: 50px;
	background-color: rgba(237, 229, 250, 0.5);
}

.inside-app-heading-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	align-items: flex-end;
	padding-top: 50px;
	padding-left: 39px;
}

.inside-app-heading-container-no-nav {
	padding-top: 50px;
	padding-left: 39px;
}

.inside-app-heading-container--hero {
	padding-left: 0px;
	padding-top: 0px;
}

.inside-app-body-container {
	padding: 24px 39px;
	padding-bottom: 60px;

	h2:first-of-type {
		margin-top: 0px;
	}

	> .app-body-container-centered {
		width: 1200px;
		margin: auto;
		padding-left: 39px;
		padding-right: 39px;
	}
}

.inside-app-heading {
	margin-bottom: 39px;
	min-height: 90px;
	font-family: "Volte-Semibold", "Volte", serif;
	color: #000000;
	font-size: 60px;
	letter-spacing: 0px;
	line-height: 1.2;
	font-weight: 600;
}

body.dark-theme .inside-app-heading {
	color: #fff;
}

.inside-app-heading-title {
	text-transform: lowercase;
	display: block;
}

.inside-app-heading-title:first-letter {
	text-transform: uppercase;
}

.inside-app-heading-no-nav {
	margin-bottom: 0px;
	min-height: 90px;
	font-family: "Volte-Semibold", "Volte", serif;
	color: #000000;
	font-size: 60px;
	letter-spacing: 0px;
	line-height: 1.2;
	font-weight: 600;
}

.inside-app-sub-nav {
	height: 90px;
	vertical-align: bottom;
	white-space: nowrap;

	a {
		margin-right: 20px;
		text-decoration: none;
		color: #4f4f4f;
		font-size: 16px;
		padding: 8px;
		white-space: nowrap;

		&:focus,
		&:hover {
			background-color: rgba(#433e66, 0.8);
			border-radius: 4px;
			color: #fff;
		}
	}

	a.active {
		background-color: #433e66;
		border-radius: 4px;
		color: #fff;

		&:focus,
		&:hover {
			background-color: rgba(#433e66, 0.8);
		}
	}
}

body.dark-theme .inside-app-sub-nav a {
	color: #fff;
}

.app-lead-text {
	font-family: "Volte", serif;
	color: #4f4f4f;
	font-size: 22px;
	letter-spacing: 0px;
	line-height: 1.3;

	p {
		margin-bottom: 40px;
	}
}

.app-footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-left: auto;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-right: 80px;
}

.app-footer-content {
	text-align: left;
	white-space: nowrap;
	font-family: "Volte", serif;
	color: #4f4f4f;
	font-size: 12px;
	letter-spacing: 0px;
	line-height: 1.3;
	margin-left: auto;
}

body.dark-theme .app-footer-content {
	color: #fff !important;
}

.app-footer-links {
	display: grid;
	grid-template-columns: repeat(3, min-content);
	grid-gap: 15px;
	margin-top: 10px;

	a {
		text-align: left;
		white-space: nowrap;
		font-family: "Volte", serif;
		color: #8300fd;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0px;
		line-height: 1.3;
		text-decoration: none;
	}
}

.form-submit-splash {
	background-color: #fff;
}

body.dark-theme {
	img[src="/Content/Images/FP-gif-preloader-10.gif" i] {
		filter: invert(0.8) hue-rotate(145deg);
	}

	.form-submit-splash {
		filter: invert(0.8) hue-rotate(145deg);

		img[src="/Content/Images/FP-gif-preloader-10.gif" i] {
			filter: none;
		}
	}
}

.mobile-message {
	display: none;
}

@media (max-width: $phone-max) {
	.inside-app-heading-title {
		font-size: 32px;
	}

	.app-footer {
		margin-left: 50px;
	}

	.mobile-message {
		display: block;
		min-height: 100vh;
		height: 100vh;
		width: 100%;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		padding: 39px;
	}

	navcontainer {
		display: none;
	}
}

body.dark-theme {
	color: #a79fae !important;
	color: #fff;

	li, p, a {
		color: #fff;
	}

	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		color: #fff;
	}

	.container a {
		color: #fff !important;
	}

	.well {
		background: #333 !important;
		border: 0 !important;
		color: #fff !important;
	}
}