﻿.error-container {
	min-height: calc(100vh);
	height: calc(100vh);
	overflow-y: auto;
	position: relative;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}

	.error-body-container {
		min-height: 100vh;
		background-color: #f7f3fd;
		padding-top: 415px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 25px;
		width: 600px;
		text-align: center;

		.error-image {
			width: 650px;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			left: calc((100vw - 650px) / 2);
			top: 0px;
		}

		h1 {
			font-weight: 700;
			font-family: "Volte-Bold", serif;
			margin-bottom: 15px;
		}

		h2 {
			font-family: "Volte", serif;
			font-size: 22px;
			line-height: 0.9em;
			margin-top: 0px;
		}

		a.error-button {
			font-family: "Volte-Medium", "Volte", serif;
			height: 36px;
			width: 144px;
			border: 0px;
			border-radius: 4px;
			-webkit-appearance: button;
			-moz-appearance: button;
			appearance: button;
			text-decoration: none;
			text-align: center;
			padding-top: 6px;
			display: inline-block;

			&.error-home-button {
				color: #ffffff;
				background-color: #8300fd;
				box-shadow: 0px 1px 3px 0px #443d69;
			}

			&.error-help-button {
				margin-left: 5px;
				color: #8300fd;
				background-color: #ffffff;
				box-shadow: 0px 1px 3px 0px #8300fd;
			}

			&:hover,
			&:focus {
				color: #ffffff;
				background-color: rgba(#8300fd, 0.8);
			}
		}

		.error-title {
			font-size: 15px;
			font-weight: bold;
			margin-top: 35px;
			margin-bottom: 35px;
			font-family: monospace;
			white-space: pre;
		}

		.error-logo {
			border: 0px;
			margin-top: 25px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
