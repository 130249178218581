﻿.password-container {
	position: relative;

	.input-field.input-validation-error {
		background-position-x: 85%;
	}
}

.password-toggle-icon {
	position: absolute;
	width: 25px;
	height: 25px;
	top: 0;
	right: 0;
	margin-top: 10px;
	margin-right: 10px;
}
