﻿.review-mode__banner {
	position: sticky;
	top: 0px;
	left: 0px;
	width: 100%;
	text-align: left;
	align-items: center;
	align-content: center;
	background-color: #8300fd;
	color: #fff;
	font-weight: bold;
	padding: 16px;
	border-bottom: 1px solid lighten(#8300fd, 10%);
	z-index: 100000;
}
