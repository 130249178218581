﻿.account-fullname-view {
    display: inline;

	&.disabled {
		text-decoration: line-through;
	}
}

.account-fullname-view,
.account-fullname-view > a {
    color: #6f6f6f;
}

.account-fullname-view > a {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

body.dark-theme {
	.account-fullname-view,
	.account-fullname-view > a {
	    color: #b9b9b9
	}
}