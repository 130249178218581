﻿.search-result,
.search-count-result,
.search-additional-result {
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.search-count-result {
	border-top: 1px solid #ddd;
}

.search-count-result,
.search-additional-result {
	background-color: #f5f5f5;
}

.account-search-control .selected-account .fa-solid,
.search-result .fa-solid {
	color: #eb7979;
}

.search-result a,
.search-additional-result {
	color: #000;
}

.search-result:hover {
	background-color: #f5f5f5;
}

.search-result-container {
	position: absolute;
	border: 1px solid #ddd;
	font-size: 14px;
	background-color: #fff;
	display: none;
	z-index: 100000;
}

body.dark-theme {
	.search-count-result, .search-additional-result {
		background-color: transparent !important;
	}

	#AccountID-Results {
		background-color: #333 !important;
		border: 0 !important;
	}

	.search-count-result {
		border: 0 !important;
	}

	.search-result-no-parent {
		background-color: #222 !important;
	}

	.search-result a {
		color: #fff;
	}

	.search-result:hover {
		background-color: #1F2739;
	}

	.search-result-container {
		background-color: #323C50;
		border: 1px solid #aaa;
	}
}
