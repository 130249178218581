﻿@import '../../variables/colors';

licensekey-stats {
	margin-bottom: 4px;

	&.deactivated {
		text-decoration: line-through;

		info {
			background-color: rgba($danger, 0.1);
		}
	}

	&.over-limit {
		info {
			background-color: rgba($danger, 0.1);
		}
	}

	&.approaching-limit {
		info {
			background-color: rgba($warning, 0.1);
		}
	}

	info {
		display: grid;
		grid-template-columns: repeat(2, auto);
		column-gap: 8px;
		font-size: 13px;
		background-color: rgba(255, 255, 255, 0.1);
		border: 0px;

		used, remaining {
			font-weight: bold;
			margin: 4px 8px;
		}

		used {
			justify-self: flex-start;
		}

		remaining {
			justify-self: flex-end;
		}

		used:before, remaining:before {
			font-weight: normal;
		}

		used:before {
			content: "Used  ";
		}

		remaining:before {
			content: "Remaining  ";
		}
	}

	message {
		display: block;
		font-size: 13px;
		font-weight: bold;
		text-align: center;
	}
}

body.dark-theme {
	licensekey-stats {
		info {
			background-color: rgba(0, 0, 0, 0.1);

			used, remaining {
				color: #fff;
			}

			used:before, remaining:before {
				font-weight: normal;
			}
		}

		message {
			color: #fff;
		}
	}
}
