﻿.licensekey-code-view {
	display: inline;

	&.disabled {
		text-decoration: line-through;
	}
}

.licensekey-code-view,
.licensekey-code-view > a {

}

.licensekey-code-view > a {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
