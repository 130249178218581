﻿.EE-heart-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	z-index: 9999;

	.heart {
		position: absolute;
		font-size: 2rem;
		animation: EE-heart-fall 5s linear;
		user-select: none;
		will-change: transform, opacity;
	}
}

@keyframes EE-heart-fall {
	0% {
		transform: scale(2);
		opacity: 1;
	}

	100% {
		transform: translateY(110vh) scale(1);
		opacity: 0;
	}
}
