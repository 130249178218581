﻿body.dark-theme {
	.form-control {
		color: #ddd;
		background-color: #1F2739;
		border: 0;
	}

	.form-control:focus,
	.form-control:focus-visible {
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.23) !important;
	}
}
