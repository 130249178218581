﻿@import '../../variables/_screen-sizes';

.marketing-container {
	z-index: 0;

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #433e66;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8300fd;
	}
}

.marketing-body-container {
	min-height: calc(100vh - 250px);
	margin-top: 39px;
}

.marketing-footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 39px;
	margin-bottom: 39px;
}

.marketing-footer-content {
	text-align: left;
	white-space: nowrap;
	font-family: "Volte", serif;
	color: #4f4f4f;
	font-size: 12px;
	letter-spacing: 0px;
	line-height: 1.3;
	margin-left: auto;
}

.marketing-footer-links {
	display: grid;
	grid-template-columns: repeat(3, min-content);
	grid-gap: 15px;
	margin-top: 10px;

	a {
		text-align: left;
		white-space: nowrap;
		font-family: "Volte", serif;
		color: #8300fd;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0px;
		line-height: 1.3;
		text-decoration: none;
	}
}

@media (max-width: $phone-max) {
	.marketing-container,
	.marketing-body-container,
	.marketing-footer {
		width: 100%;
	}

	.marketing-container {
		min-height: auto;
		height: auto;
	}

	.marketing-body-container {
		margin-top: 0px;
		min-height: auto;
		height: auto;
	}

	.marketing-footer {
		display: block;
		padding-left: 15px;
	}
}
